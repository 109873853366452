import routerNames from '@/router/routerNames';
export default [
  {
    path: '/exhibits',
    name: routerNames.EXHIBITS.list,
    component: () => import('@/views/Exhibits/ExhibitsView'),
    meta: {
      title: 'Музейний фонд',
      isMuseumFund: true,
      categoryRoot: routerNames.EXHIBITS.list,
      fullContent: true
    }
  },
  {
    path: '/exhibits/:id',
    name: routerNames.EXHIBITS.detail,
    component: () => import('@/views/Exhibits/ExhibitDetailView'),
    meta: {
      title: 'Перегляд експонату',
      isMuseumFund: true,
      categoryRoot: routerNames.EXHIBITS.list
    }
  },
  {
    path: '/exhibits/:id/update',
    name: routerNames.EXHIBITS.change,
    component: () => import('@/views/Exhibits/ExhibitUpdateView'),
    meta: {
      title: 'Редагування експонату',
      isMuseumFund: true,
      categoryRoot: routerNames.EXHIBITS.list
    }
  }
];
