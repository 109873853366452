import {MINISTRY_OF_CULTURE_ABBREVIATION} from '@/utils/constants';

export const PRICE_CURRENCY = Object.freeze({
  UAH: 'Українська гривня (UAH)',
  AUD: 'Австралійський долар (AUD)',
  CAD: 'Канадський долар (CAD)',
  CHF: 'Швейцарський франк (CHF)',
  EUR: 'Євро (EUR)',
  GBP: 'Фунт стерлінгів (GBP)',
  PLN: 'Польський злотий (PLN)',
  USD: 'Долар США (USD)',
  SUR: 'Радянський рубль (SUR)',
  UAK: 'Український купоно-карбованець (UAK)'
});

export const PRICE_TYPES = Object.freeze({
  ESTIMATED: 1,
  INSURANCE: 2,
  PURCHASE: 3,
  1: 'Оціночна вартість',
  2: 'Страхова вартість',
  3: 'Вартість придбання',
});

export const SIZE_TYPES = Object.freeze({
  LENGTH: 1,
  WIDTH: 2,
  HEIGHT: 3,
  DIAMETER: 4,
  MASS: 5,
  1: 'Довжина',
  2: 'Ширина',
  3: 'Висота',
  4: 'Діаметр',
  5: 'Вага'
});

export const SIZE_UOM = Object.freeze({
  'мм': 'мм',
  'см': 'см',
  'м': 'м',
  'г': 'г',
  'кг': 'кг',
  'т': 'т',
});

export const NUMBER_TYPE = Object.freeze({
  INVENTORY: 2,
  1: 'Первинна реєстрація ОФ', // initial-registration
  2: 'Інвентарний облік', // inventory-accounting
  3: 'Спецінвентарний облік', // special-inventory-accounting
  4: 'Тимчасове зберігання', // temporary-storage
  5: 'Номер ЄРДР', // edpr
  6: 'Первинна реєстрація НДФ', // first-registration-ndf
  7: 'Інший номер' // other
});


export const NAME_LANGUAGES = Object.freeze({
  uk: 'Українська',
  en: 'Англійська',
  ru: 'Російська',
  fr: 'Французька',
  es: 'Іспанська',
  de: 'Німецька',
  la: 'Латиниця'
});

export const IMAGE_TYPES = Object.freeze({
  1: 'Вигляд ззаду (реверс)',
  2: 'Вигляд спереду (аверс)',
  3: 'Вигляд зверху',
  4: 'Вигляд знизу',
  5: 'Вигляд зліва',
  6: 'Вигляд справа'
});

export const EXHIBIT_STATES = Object.freeze({
  TEMPORARY_STORAGE: 2,
  DEREGISTERED: 3,
  TEMPORARY_STORAGE_OUTSIDE_COUNTRY: 9,
  1: 'На постійному зберіганні', //  STATE_PERMANENT_STORAGE
  2: 'На тимчасовому зберіганні в межах країни',
  3: 'Знято з обліку',
  4: 'Втрачено в результаті Другої світової війни', // STATE_LOST_RESULT_WORLD_WAR_II
  5: 'У національному розшуку', // STATE_NATIONALLY_WANTED
  6: 'Викрадено', // STATE_STOLEN
  7: 'Не виявлено в ході останнього звірення', // STATE_NOT_DETECTED_LAST_RECONCILIATION
  8: 'Втрачено в результаті війни з російською федерацією', // STATE_LOST_RESULT_OF_WAR
  9: 'На тимчасовому зберіганні за межами країни',
  10: 'Знищено', // DESTROYED
  11: 'Евакуйовано'
});

export const EXHIBIT_STATUSES = Object.freeze({
  DRAFT: 1,
  PENDING: 2,
  ACTIVE: 3,
  1: 'Чернетка',
  2: 'На розгляді',
  3: 'Підписано',
});

export const PERSON_TYPES = Object.freeze({
  INDIVIDUAL: 1,
  1: 'Фізична особа',
  2: 'Юридична особа',
  3: 'Група (об\'єднання)'
});

export const EXHIBIT_CONDITION_STATES = Object.freeze({
  GOOD: 1,
  SATISFACTORY: 2,
  BAD: 3,
  1: 'Добрий (без пошкоджень)',
  2: 'Задовільний',
  3: 'Незадовільний',
});

export const EXHIBIT_CONDITION_STATES_TIPS = Object.freeze({
  1: 'Зберігається у своєму оригінальному стані без жодних пошкоджень або змін',
  2: 'Не має механічних пошкоджень, але має забруднення',
  3: 'Має механічні пошкодження'
});

export const CLASSIFICATION_CHARACTERISTICS_TYPES = Object.freeze({
  'boolean': 'Логічний',
  'text': 'Текст',
  'number': 'Число',
  'date': 'Дата',
  'linear_reference': 'Лінійний довідник',
});

export const PERSONS_GENDERS = Object.freeze({
  0: 'Жіноча',
  1: 'Чоловіча',
});

export const ACQUISITION_METHODS = Object.freeze({
  1: 'Закупівля',
  2: 'Дарування (пожертвування)',
  3: 'Заповіт',
  4: 'Рішення суду',
  5: 'Передача обернених у дохід держави',
  6: 'Передача виявлених під час археологічних експедицій',
  7: 'Передача виявлених під час етнографічних експедицій',
  8: 'Передача виявлених під час науково-природничих експедицій',
  9: 'Передача виявлених під час інших експедицій',
  10: 'Передача під час будівельних робіт',
  11: 'Передача під час ремонтних робіт',
  12: 'Передача під час реставраційних робіт',
  13: 'Повернення розшуканих в Україну',
  14: 'Передача вилучених на митниці',
  15: 'Надходження іншими способами',
});

export const EXHIBIT_EVENT_TYPES = Object.freeze({
  CREATION: 1,
  DISCOVERY: 2,
  LIVING: 3,
  EVACUATION: 17,
  1: 'Створення',
  2: 'Виявлення',
  3: 'Побутування',
  4: 'Реставрація',
  5: 'Втрата під час Другої світової війни',
  6: 'Повернення після втрати під час Другої світової війни',
  7: 'Втрата під час війни з російською федерацією',
  8: 'Повернення після втрати під час війни з російською федерацією',
  9: 'Викрадення',
  10: 'Повернення',
  11: 'Проведення державної експертизи',
  12: 'Повідомлення про конфіскацію',
  13: 'Повідомлення про розшук за запитами інших держав',
  14: 'Отримання інформації про можливе місце розташування',
  15: 'Знищення',
  16: 'Зроблено копію',
  17: 'Евакуація',
});

export const EXHIBIT_NAME_TYPES = Object.freeze({
  INVENTORY: 5,
  1: 'Авторська',
  2: 'Народна',
  3: 'Описова',
  4: 'Наукова',
  5: 'Інвентарна'
});

export const TRANSFERS_STATUSES = Object.freeze({
  ...EXHIBIT_STATUSES,
  3: 'Погоджено',
});

export const TRANSFER_TYPES = Object.freeze({
  ABROAD: 1,
  UKRAINE: 2,
  RETURN: 3,
  1: 'Передача за кордон на ТЗ',
  2: 'Передача в межах України на ТЗ',
  3: 'Повернення з ТЗ'
});

export const TRANSFER_STATEMENT_TYPES = Object.freeze({
  ABROAD: 1,
  UKRAINE: 2,
  1: 'За кордон на ТЗ',
  2: 'В межах України на ТЗ',
});

export const TRANSFER_STATEMENT_KINDS = Object.freeze({
  PROTOCOL: 1,
  CERTIFICATE: 2,
  1: 'Погодження про передачу за кордон',
  2: 'Погодження про передачу за кордон + Свідоцтво',
});

export const STATEMENT_STATUSES = Object.freeze({
  DRAFT: 1,
  PENDING_MUSEUM: 2,
  PENDING_MKIP: 3,
  APPROVAL: 4,
  REJECTED: 5,
  1: 'Чернетка',
  2: 'На розгляді у керівника музею',
  3: `На розгляді ${MINISTRY_OF_CULTURE_ABBREVIATION}`,
  4: 'Погоджено',
  5: 'Відхилено',
});

export const STATEMENT_REJECTED_REASON = Object.freeze({
  1: 'Відсутні гарантії щодо забезпечення надійного зберігання та повернення культурних цінностей в обумовлений угодою термін',
  2: 'Заявлені для тимчасового вивезення культурні цінності в такому стані, що не можна змінювати умови їх зберігання',
  3: 'Культурні цінності є предметом спору щодо права власності на них',
  4: 'Страхова вартість культурних цінностей, заявлених для тимчасового вивезення, не відповідає їх реальній вартості',
  5: 'Культурні цінності, заявлені для тимчасового вивезення, перебувають в розшуку',
  6: 'У державі, до якої передбачено здійснити тимчасове вивезення культурних цінностей, сталося стихійне лихо, ' +
    'виникли збройні конфлікти, введено надзвичайний стан або існують інші обставини, що перешкоджають забезпеченню ' +
    'надійного зберігання і поверненню культурних цінностей, які тимчасово вивозяться в цю державу',
  7: 'Подання суб\'єктом господарювання неповного пакета документів, необхідних для одержання документа дозвільного ' +
    'характеру, згідно із встановленим вичерпним переліком',
  8: 'Виявлення в документах, поданих суб\'єктом господарювання, недостовірних відомостей',
  9: 'Негативний висновок за результатами проведених експертиз та обстежень або інших наукових і технічних оцінок, ' +
    'необхідних для видачі документа дозвільного характеру',
  10: 'Інша причина (детальна інформація в коментарі)'
});

export const TRANSFER_PURPOSES = Object.freeze({
  EXPOSURE: 1,
  1: 'Експонування',
  2: 'Атрибуція',
  3: 'Дослідження',
  4: 'Реставрація',
  5: 'Експертиза',
});

export const FILE_TYPES = {
  REQUEST_LETTER: 1,
  STATE_EXPERTISE_CONCLUSION: 10,
  ORDER: 11,
  CERTIFICATE: 12,
  REFUSAL_NOTICE: 13,
  DELIVERY_RECEIPT: 15,
  REPORT_RETURN: 17,
  OTHER: 21,
  STATEMENT: 22,
  1: 'Лист-клопотання',
  2: 'Гарантійний лист', // WARRANTY_LETTER
  3: 'Договір страхування музейних предметів', // INSURANCE_CONTRACT
  4: 'Державні гарантії фінансового покриття', // GOVERNMENT_GUARANTEES
  5: 'Контракт (угода)', // CONTRACT
  6: 'Звіт про системи безпеки (facility report)', // SECURITY_REPORT
  7: 'Рішення реставраційної ради музею', // RESTORATION_COUNCIL_DECISION
  8: 'Висновок Національного науково-дослідного реставраційного центру', // NATIONAL_CONCLUSION
  9: 'Документ про право власності', // PROPERTY_RIGHTS_DOCUMENT
  10: 'Висновок державної експертизи', // STATE_EXPERTISE_CONCLUSION
  11: 'Наказ',
  12: 'Свідоцтво',
  13: 'Повідомлення про відмову',
  14: 'Заява-доручення', // ORDER_FORM
  15: 'Акт приймання-передавання',
  16: 'Перелік предметів', // ITEM_LIST
  17: 'Звіт про повернення предметів', // REPORT_RETURN
  18: 'Рішення органа управління', // MANAGEMENT_DECISION
  19: 'Статут/положення музею', //STATUTE
  20: 'Протокол ФЗК/Витяг з протоколу ФЗК', // PROTOCOL_EXTRACT
  21: 'Інші файли',
  22: 'Заява',
  get transferTypes() {
    return [this.OTHER, this.DELIVERY_RECEIPT];
  },
  get statementsTypes() {
    return Object.keys(this)
      .filter((key) => key > 0 && ![FILE_TYPES.ORDER, FILE_TYPES.CERTIFICATE, FILE_TYPES.REFUSAL_NOTICE,
        FILE_TYPES.REPORT_RETURN].includes(+key))
      .map((type) => +type);
  }
};

export const STATEMENT_HISTORY = Object.freeze({
  CREATION: 1,
  REVIEW_TO_MUSEUM: 2,
  REVIEW_TO_MKIIP: 3,
  ADDED_ORDER: 4,
  ADDED_CERTIFICATE: 5,
  FOR_REVISION: 6,
  REJECTED: 7,
  DELETED: 8,
  LOCATION_TO: 9,
  LOCATION_FROM: 10,
  APPROVED: 11,
  PENDING: 12,
  1: 'Створено',
  2: 'Відправлено на розгляд керівнику музею',
  3: `Відправлено на розгляд ${MINISTRY_OF_CULTURE_ABBREVIATION}`,
  4: 'Додано Наказ',
  5: 'Додано Свідоцтво',
  6: 'Відправлено на доопрацювання',
  7: 'Відхилено',
  8: 'Видалено',
  9: 'Змінено місце розташування предметів (передача на ТЗ)',
  10: 'Змінено місце розташування предметів (повернення з ТЗ)',
  11: 'Погоджено',
  12: 'Відправлено на розгляд',
  get arrowTypes() {
    return [this.REVIEW_TO_MUSEUM, this.REVIEW_TO_MKIIP, this.PENDING];
  },
  get editTypes() {
    return [this.ADDED_ORDER, this.ADDED_CERTIFICATE];
  },
  /**
   * @param {number} type
   */
  iconClass(type) {
    if (type === this.CREATION) {
      return 'i-plus i-16 white';
    } else if (this.arrowTypes.includes(type)) {
      return 'i-arrow-right i-16 white';
    } else if (this.editTypes.includes(type)) {
      return 'i-edit-simple i-16';
    } else if (type === this.DELETED) {
      return 'i-delete i-16 white';
    } else if (type === this.REJECTED) {
      return 'i-circle-slash i-16 white';
    } else if (type === this.APPROVED) {
      return 'i-check-circle i-16 white';
    } else if (type === this.FOR_REVISION) {
      return 'i-reply i-16 white';
    } else if (type === this.LOCATION_FROM) {
      return 'i-horizontal-align-left i-16 white';
    } else if (type === this.LOCATION_TO) {
      return 'i-horizontal-align-right i-16 white';
    } else {
      return '';
    }
  }
});
