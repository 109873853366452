<script setup>
import BubbleNotification from '@/components/common/BubbleNotification';
defineProps({
  backButton: {type: Boolean, default: true},
  backDisabled: {type: Boolean, default: false},
  backTitle: {type: String, default: 'До списку'},
  deleteButton: {type: Boolean, default: false},
  confirmButton: {type: Boolean, default: false},
  disableConfirm: {type: Boolean, default: false},
  confirmClass: {type: String, default: 'btn-white'},
  rejectButton: {type: Boolean, default: false},
});
const emit = defineEmits(['delete', 'save-note', 'reject', 'back', 'confirm']);
</script>

<template>
  <footer class="footer">
    <BubbleNotification/>
    <div class="footer__btns d-flex align-cente justify-between">
      <div class="d-flex gap-4">
        <button
          class="btn-grey fs-14 fw-500"
          v-if="backButton"
          :disabled="backDisabled"
          @click="emit('back')"
        >
          <i class="i-reply"/> {{ backTitle }}
        </button>
        <button
          class="btn-grey fs-14 fw-500"
          v-if="deleteButton"
          @click="emit('delete')"
        >
          <i class="i-delete white"/> Видалити
        </button>
        <slot name="dropdown-left"/>
        <slot name="custom-left"/>
      </div>
      <div class="d-flex gap-4">
        <button
          class="btn-reject fs-14 fw-500"
          v-if="rejectButton"
          @click="emit('reject')"
        >
          <slot name="reject-btn"></slot> <i class="i-circle-slash black"/>
        </button>
        <slot name="processing" />
        <button
          class="fs-14 fw-500"
          :class="confirmClass"
          :disabled="disableConfirm"
          v-if="confirmButton"
          @click="emit('confirm')"
        >
          <slot name="confirm-btn" />
        </button>
      </div>
    </div>
  </footer>
</template>

