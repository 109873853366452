import api from '@/api';
import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {isEmpty} from 'lodash';
import {
  DEFAULT_MUSEUM_ROLES,
  FULL_MKIP_ROLES,
  MKIP_ROLES,
  USER_ROLES,
  USER_STATUS,
  UTCD_ROLES
} from '@/utils/user';
import exhibits from '@/store/exhibits';
import {
  storageGroups,
  collections,
  persons,
  linearReferences,
  storageLocations,
} from '@/store/references';
import {characteristics, thesaurus} from '@/store/thesaurus';
import {users} from '@/store/management';
import {
  transferStatements,
  coordStatements,
  transfers,
  exhibitions
} from '@/store/documents';

export default createStore({
  state: {
    user: {},
    notification: {
      text: '',
      type: '',
      svg: '',
      timeoutId: null,
    },
    ready: false // for widget
  },
  plugins: [
    createPersistedState({
      key: 'mc-data',
      paths: ['user']
    })
  ],

  getters: {
    isActiveUser: (state) => state.user?.status === USER_STATUS.ACTIVE,
    userRoleIds: (state) => state.user.roles?.map((item) => item.id) || [],
    hasRole: (state) => (roleIds) => state.user.roles?.some((role) => roleIds.includes(role.id)),
    hasAllRoles: (state, getters) => (roleIds) => roleIds.every((role) => getters.userRoleIds.includes(role)),
    isSuperAdmin: (state, getters) => getters.userRoleIds.includes(USER_ROLES.SUPER_ADMIN),
    isUtcdAdmin: (state, getters) => getters.userRoleIds.includes(USER_ROLES.ADMIN_UTCD),
    isMuseumEmployee: (state) => state.user.organization_id,
    hasMinCultRole: (state, getters) => getters.hasRole(MKIP_ROLES),
    hasUtcdRole: (state, getters) => getters.hasRole(UTCD_ROLES),
    documentsModuleViewPermission: (state, getters) => getters
      .hasRole([...FULL_MKIP_ROLES, USER_ROLES.ADMIN_UTCD, ...DEFAULT_MUSEUM_ROLES]),
    isOwnerAdmin: (state, getters) => (ownerId) => getters.userRoleIds
      .includes(USER_ROLES.ADMIN) && state.user.organization_id === ownerId
  },

  mutations: {
    setUser(state, payload) {
      const data = {};
      if (!isEmpty(payload)) {
        ['id', 'name', 'storage_groups', 'roles', 'organization', 'organization_id', 'status']
          .forEach((key) => data[key] = payload[key]);
      }
      state.user = data;
    },
    /**
     * @param state
     * @param {boolean} ready
     */
    setReady(state, ready) {
      state.ready = ready;
    },
    /**
     * @param {object} state
     * @param {string} text
     * @param {string} type
     * @param {string} svg
     * @param {number} timeout
     */
    setNotification(state, {text, type, svg, timeout = 4000}) {
      if (state.notification.timeoutId) {
        clearTimeout(state.notification.timeoutId);
        state.notification.timeoutId = null;
      }
      state.notification.text = text;
      state.notification.type = type || 'reject';
      state.notification.svg = svg || 'alert';
      state.notification.timeoutId = setTimeout(() => {
        state.notification.text = '';
        state.notification.type = '';
        state.notification.svg = '';
        state.notification.timeoutId = null;
      }, timeout);
    }
  },

  actions: {
    /**
     * @param commit
     * @param {Object} payload data with user credentials
     */
    async logIn({commit}, payload) {
      const [response, error] = await api.auth.loginEmail(payload);
      if (error) {
        return false;
      }
      commit('setUser', response.data.user);
      return true;
    },
    /**
     * @param commit
     * @param state
     * @param {number | null } userId
     */
    async getUser({commit, state}, userId = null) {
      const data = JSON.parse(window.localStorage.getItem('mc-data'));
      const [response, error] = await api.users.detail(userId ? userId : data.user.id);
      if (!error) {
        if (!userId) {
          commit('setUser', response.data);
        }
        return response.data;
      }
    }
  },
  modules: {
    exhibits,
    characteristics,
    thesaurus,
    storageGroups,
    collections,
    persons,
    linearReferences,
    storageLocations,
    users,
    transferStatements,
    coordStatements,
    transfers,
    exhibitions
  }
});
