import {ClientMixin, urls} from '@/api/client';

export class Exhibits extends ClientMixin {
  /** @param {object} getParams */
  list = async (getParams = {}) => this.api.get(urls.exhibits.list, getParams);

  count = async () => this.api.get(urls.exhibits.count);

  /** @param {FormData} data */
  import = async (data) => this.api.post(urls.exhibits.import, data);

  /** @param {number} exhibitId */
  detail = async (exhibitId) => this.api.get(urls.exhibits.detail.format({exhibitId}));

  create = async () => this.api.post(urls.exhibits.list);

  /**
   * @param {number} exhibitId
   * @param {object} data
   */
  patch = async (exhibitId, data) => {
    return this.api.put(urls.exhibits.detail.format({exhibitId}), data);
  }

  /**
   * @param {number} exhibitId
   * @param {number} collectionId
   */
  setCollection = async (exhibitId, collectionId) => {
    return this.api.post(urls.exhibits.collections.format({collectionId, exhibitId}));
  }
  /**
   * @param {number} exhibitId
   * @param {number} collectionId
   */
  removeCollection = async (exhibitId, collectionId) => {
    return this.api.delete(urls.exhibits.collections.format({collectionId, exhibitId}));
  }

  /**
   * @param {number} exhibitId
   * @param {number} groupId
   */
  setStorageGroup = async (exhibitId, groupId) => {
    return this.api.post(urls.exhibits.storageGroups.format({groupId, exhibitId}));
  }
  /**
   * @param {number} exhibitId
   * @param {number} groupId
   */
  removeStorageGroup = async (exhibitId, groupId) => {
    return this.api.delete(urls.exhibits.storageGroups.format({groupId, exhibitId}));
  }
  /**
   * @param {number} exhibitId
   * @param {number} personId
   */
  setPersons = async (exhibitId, personId) => {
    return this.api.post(urls.exhibits.persons.format({personId, exhibitId}));
  }
  /**
   * @param {number} exhibitId
   * @param {number} personId
   */
  removePersons = async (exhibitId, personId) => {
    return this.api.delete(urls.exhibits.persons.format({personId, exhibitId}));
  }
  /**
   * @param {number} exhibitId
   * @param {number} characteristicId
   * @param {object} data
   */
  setCharacteristic = async (exhibitId, characteristicId, data) => {
    return this.api.post(urls.exhibits.characteristics.format({exhibitId, characteristicId}), data);
  }

  removeCharacteristic = async (exhibitId, characteristicId, data) => {
    return this.api.archive(urls.exhibits.characteristics.format({exhibitId, characteristicId}), data);
  }

  /**
   * @param {number} exhibitId
   * @param {object} data
   */
  destroy = async (exhibitId, data) => {
    return this.api.archive(urls.exhibits.detail.format({exhibitId}), data);
  }

  /** @param {number} exhibitId */
  getSignData = async (exhibitId) => {
    return this.api.get(urls.exhibits.getSignData.format({exhibitId}));
  }

  /**
   * @param {number} exhibitId
   * @param {object} data
   */
  sign = async (exhibitId, data) => {
    return this.api.post(urls.exhibits.sign.format({exhibitId}), data);
  }
  /** @param {number} exhibitId */
  copy = async (exhibitId) => {
    return this.api.post(urls.exhibits.copy.format({exhibitId}));
  }

  /** @param {number} id */
  getSignaturesCountByClassification = async (id) => {
    return this.api.get(urls.exhibits.signaturesCountByClassification.format({id}));
  }
  /** @param {number} id */
  getSignaturesCountByCollection = async (id) => {
    return this.api.get(urls.exhibits.signaturesCountByCollection.format({id}));
  }

  /** @param {number} id */
  getSignaturesCountByMaterial = async (id) => {
    return this.api.get(urls.exhibits.signaturesCountByMaterial.format({id}));
  }

  /** @param {number} id */
  getSignaturesCountByTechnique = async (id) => {
    return this.api.get(urls.exhibits.signaturesCountByTechnique.format({id}));
  }

  /** @param {number} id */
  getSignaturesCountByPerson = async (id) => {
    return this.api.get(urls.exhibits.signaturesCountByPersons.format({id}));
  }

  /** @param {number} id */
  getSignaturesCountByStorageGroup = async (id) => {
    return this.api.get(urls.exhibits.signaturesCountByStorageGroup.format({id}));
  }

  /** @param {number} id */
  getSignaturesCountByStorageLocation = async (id) => {
    return this.api.get(urls.exhibits.signaturesCountByStorageLocation.format({id}));
  }
}

export class ExhibitNames extends ClientMixin {
  /** @param {object} data */
  create = async (data) => this.api.post(urls.exhibitNames.list, data);

  /**
   * @param {number} nameId
   * @param {object} data
   */
  patch = async (nameId, data) => {
    return this.api.put(urls.exhibitNames.detail.format({nameId}), data);
  }
  /** @param {number} nameId */
  destroy = async (nameId) => this.api.delete(urls.exhibitNames.detail.format({nameId}));
}

export class Numbers extends ClientMixin {
  /** @param {object} data */
  create = async (data) => this.api.post(urls.numbers.list, data);

  /**
   * @param {number} numberId
   * @param {object} data
   */
  patch = async (numberId, data) => {
    return this.api.put(urls.numbers.detail.format({numberId}), data);
  }
  /** @param {number} numberId */
  destroy = async (numberId) => this.api.delete(urls.numbers.detail.format({numberId}));
}

export class Sizes extends ClientMixin {
  /**
   * @param {exhibitId} exhibitId
   * @param {object} data
   */
  create = async (exhibitId, data) => {
    return this.api.post(urls.sizes.exhibit.format({exhibitId}), data);
  }
  /**
   * @param {number} sizeId
   * @param {object} data
   */
  patch = async (sizeId, data) => this.api.put(urls.sizes.detail.format({sizeId}), data);
  /** @param {number} sizeId */
  destroy = async (sizeId) => this.api.delete(urls.sizes.detail.format({sizeId}));
}

export class Prices extends ClientMixin {
  /** @param {object} data */
  create = async (data) => this.api.post(urls.prices.list, data);

  /**
   * @param {number} priceId
   * @param {object} data
   */
  patch = async (priceId, data) => {
    return this.api.put(urls.prices.detail.format({priceId}), data);
  }
  /** @param {number} priceId */
  destroy = async (priceId) => this.api.delete(urls.prices.detail.format({priceId}));
}

export class Images extends ClientMixin {
  /**
   * @param {number} exhibitId
   * @param {object} data
   */
  exhibitsCreate = async (exhibitId, data) => {
    return this.api.post(urls.images.exhibit.format({exhibitId}), data);
  }

  /**
   * @param {number} imageId
   * @param {object} data
   */
  patch = async (imageId, data) => this.api.put(urls.images.detail.format({imageId}), data);

  /** @param {number} imageId */
  destroy = async (imageId) => this.api.delete(urls.images.detail.format({imageId}));

  /**
   * @param {number} imageId
   * @param {number} personId
   */
  addPerson = async (imageId, personId) => {
    return this.api.post(urls.images.person.format({imageId, personId}));
  }

  /**
   * @param {number} imageId
   * @param {number} personId
   */
  removePerson = async (imageId, personId) => {
    return this.api.delete(urls.images.person.format({imageId, personId}));
  }
}

export class Conditions extends ClientMixin {
  /** @param {object} data */
  create = async (data) => this.api.post(urls.conditions.list, data);

  /**
   * @param {number} conditionId
   * @param {object} data
   */
  patch = async (conditionId, data) => {
    return this.api.put(urls.conditions.detail.format({conditionId}), data);
  }

  /** @param {number} conditionId */
  destroy = async (conditionId) => this.api.delete(urls.conditions.detail.format({conditionId}));

  /**
   * @param {number} conditionId
   * @param {number} personId
   */
  addPerson = async (conditionId, personId) => {
    return this.api.post(urls.conditions.person.format({conditionId, personId}));
  }

  /**
   * @param {number} conditionId
   * @param {number} personId
   */
  removePerson = async (conditionId, personId) => {
    return this.api.delete(urls.conditions.person.format({conditionId, personId}));
  }
}

export class ExhibitEvents extends ClientMixin {
  /** @param {object} data */
  create = async (data) => this.api.post(urls.events.list, data);

  /**
   * @param {number} id
   * @param {object} data
   */
  patch = async (id, data) => this.api.put(urls.events.detail.format({id}), data);

  /** @param {number} id */
  destroy = async (id) => this.api.delete(urls.events.detail.format({id}));

  /**
   * @param {number} id
   * @param {number} personId
   */
  addPerson = async (id, personId) => this.api.post(urls.events.persons.format({id, personId}));

  /**
   * @param {number} id
   * @param {number} personId
   */
  removePerson = async (id, personId) => this.api.delete(urls.events.persons.format({id, personId}));
}

export class Gemstones extends ClientMixin {
  /** @param {object} data */
  create = async (data) => this.api.post(urls.gemstones.list, data);

  /**
   * @param {number} id
   * @param {object} data
   */
  patch = async (id, data) => this.api.put(urls.gemstones.detail.format({id}), data);

  /** @param {number} id */
  destroy = async (id) => this.api.delete(urls.gemstones.detail.format({id}));
}

export class PreciousMetals extends ClientMixin {
  /** @param {object} data */
  create = async (data) => this.api.post(urls.preciousMetals.list, data);

  /**
   * @param {number} id
   * @param {object} data
   */
  patch = async (id, data) => this.api.put(urls.preciousMetals.detail.format({id}), data);

  /** @param {number} id */
  destroy = async (id) => this.api.delete(urls.preciousMetals.detail.format({id}));
}

export class ExhibitDescriptions extends ClientMixin {
  /** @param {object} data */
  create = async (data) => this.api.post(urls.exhibitDescriptions.list, data);

  /**
   * @param {number} descriptionId
   * @param {object} data
   */
  patch = async (descriptionId, data) => {
    return this.api.put(urls.exhibitDescriptions.detail.format({descriptionId}), data);
  }
  /**
   * @param {number} descriptionId
   */
  destroy = async (descriptionId) => {
    return this.api.delete(urls.exhibitDescriptions.detail.format({descriptionId}));
  }
}

export class Compositions extends ClientMixin {
  /** @param {object} data */
  create = async (data) => this.api.post(urls.compositions.list, data);

  /**
   * @param {number} compositionId
   * @param {object} data
   */
  patch = async (compositionId, data) => {
    return this.api.put(urls.compositions.detail.format({compositionId}), data);
  }

  /** @param {number} compositionId */
  destroy = async (compositionId) => this.api.delete(urls.compositions.detail.format({compositionId}));
}
