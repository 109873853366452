<script setup>
import {computed} from 'vue';
import {useRouter, useRoute} from 'vue-router';
import {useLinkTo} from '@/hooks/useNavigation';
import routerNames from '@/router/routerNames';
import {useStore} from 'vuex';

defineProps({
  sectionName: {type: String, default: ''},
  showQuery: {type: Boolean, default: false},
  filters: {type: Array, default: () => []}
});
const emit = defineEmits(['show-modal', 'remove-filter']);

const route = useRoute(), router = useRouter();
const store = useStore();
const homeLink = computed(() => store.getters.hasMinCultRole || store.getters.hasUtcdRole
  ? {name: routerNames.AUTH.home}
  : linkTo(routerNames.EXHIBITS.list));

const rootRoute = computed(() => router.options.routes
  .find((item) => item.name === route.meta?.categoryRoot) || {});
const {linkTo} = useLinkTo();
</script>


<template>
  <div class="nav-panel">
    <div class="breadcrumbs">
      <router-link class="breadcrumbs__crumb" :to="homeLink">
        <i class="i-home"/>
      </router-link>
      <span class="breadcrumbs__crumb ml-12 fs-12" v-if="sectionName" >
        <span class="mr-12">/</span>{{ sectionName }}
      </span>
      <router-link class="breadcrumbs__crumb ml-12 fs-12" :to="linkTo(route.meta.categoryRoot)">
        <span class="mr-12">/</span>{{ rootRoute.meta?.title }}
      </router-link>
    </div>
    <slot/>
    <div class="app-filters" v-if="showQuery">
      <div class="filters__container text fs-13 fw-500 text-black">
        Фільтр <span class="text text-grey">{{ filters.length }}</span>
        <div class="filters__popup fs-10" v-if="filters.length">
          <div class="popup__head-border w-100"><i class="i-triangle-down black rotated"/></div>
          <div class="popup__content">
            <div class="active-filter" v-for="(filter, index) in filters" :key="index">
              {{ filter.value }}
              <button class="active-filter__remove" @click="emit('remove-filter', filter)">
                <i class="i-x"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      <button class="filters__btn" @click="emit('show-modal')"><i class="i-filter"/></button>
    </div>
  </div>
</template>

