import {ClientMixin, urls} from '@/api/client';

export class Organizations extends ClientMixin {
  /** @param {object} getParams optional GET params */
  list = async (getParams = {}) => this.api.get(urls.organizations.list, getParams);

  /** @param {number} organizationId */
  detail = async (organizationId) => {
    return this.api.get(urls.organizations.detail.format({organizationId}));
  }
  /** @param {object} getParams */
  search = async (getParams) => this.api.get(urls.organizations.search, getParams);

  /** @param {FormData} file */
  upload = async (file) => this.api.post(urls.organizations.upload, file);
}

export class Users extends ClientMixin {
  /** @param {object} getParams optional GET params */
  list = async (getParams = {}) => this.api.get(urls.users.list, getParams);

  /** @param {number} userId */
  detail = async (userId) => this.api.get(urls.users.detail.format({userId}));

  /**
   * @param {number} userId
   * @param {object} data
   */
  patchUser = async (userId, data) => this.api.put(urls.users.detail.format({userId}), data);

  /** @param {number} userId */
  detachOrganization = async (userId) => {
    return this.api.delete(urls.users.detachOrganization.format({userId}));
  }

  /**
   * @param {number} userId
   * @param {number} groupId
   */
  addStorageGroups = async (userId, groupId) => {
    return this.api.post(urls.users.storageGroups.format({userId, groupId}));
  }

  /**
   * @param {number} userId
   * @param {number} groupId
   */
  removeStorageGroups = async (userId, groupId) => {
    return this.api.delete(urls.users.storageGroups.format({userId, groupId}));
  }

  /**
   * @param {number} organizationId
   * @param {object} data
   */
  organizationConnect = async (organizationId, data = {}) => {
    return this.api.post(urls.users.connect.format({organizationId}), data);
  }

  /** @param {object} data */
  updatePassword = async (data) => this.api.post(urls.users.updatePassword, data);

  /** @param {object} data */
  updateEmail = async (data) => this.api.post(urls.users.updateEmail, data);
}

export class Positions extends ClientMixin {
  /** @param {object} getParams optional GET params*/
  list = async (getParams = {}) => this.api.get(urls.positions.list, getParams);
}
