import {ClientMixin, urls} from '@/api/client';

export class Auth extends ClientMixin {
  /** @param {object} data */
  loginEmail = async (data) => this.api.post(urls.auth.loginEmail, data);

  getSignToken = async () => this.api.get(urls.auth.signToken);

  /** @param {string} sign */
  login = async (sign) => this.api.post(urls.auth.login, {sign});

  logout = async () => this.api.post(urls.auth.logout);

  csrfToken = async () => this.api.get(urls.auth.csrfToken);

  /** @param {string} type */
  getToken = async (type = 'document-token') => this.api.post(urls.auth.apiToken, {type});

  /** @param {object} data */
  forgotPassword = async (data) => this.api.post(urls.auth.forgotPassword, data);

  /** @param {object} data */
  resetPassword = async (data) => this.api.post(urls.auth.resetPassword, data);
}

export class Settings extends ClientMixin {
  list = async () => await this.api.get(urls.settings.list, {page: 1, limit: 20});

  /**
   * @param {number} settingId
   * @param {{value: string}} data
   */
  patch = async (settingId, data) => await this.api
    .put(urls.settings.detail.format({settingId}), data);
}

export class DataGovUa extends ClientMixin {
  list = async () => await this.api.get(urls.dataGovUa.list, {page: 1, limit: 20});

  /**
   * @param {number} registryId
   */
  patch = async (registryId) => await this.api
    .put(urls.dataGovUa.detail.format({registryId}), {});
}
