import {
  USER_ROLES,
  MKIP_ROLES,
  UTCD_ROLES,
  SIGNERS,
  LEAD_ROLES,
  checkRole
} from '@/utils/user';


export const users = {
  namespaced: true,
  getters: {
    viewPermission: (state, getters, rootState, rootGetters) => (...args) => {
      if (rootGetters.hasMinCultRole || rootGetters.hasUtcdRole) {
        return true;
      }
      if (!rootState.user.organization_id) {
        return false;
      }
      return rootGetters.hasRole(LEAD_ROLES) && args.includes(rootState.user.organization_id);
    },
    changePermission: (state, getters, rootState, rootGetters) => (orgId, roles) => {
      if (rootGetters.isSuperAdmin) {
        return !checkRole(roles, UTCD_ROLES);
      }
      if (rootGetters.isUtcdAdmin) {
        return !orgId && !checkRole(roles, MKIP_ROLES);
      }
      if (rootGetters.hasRole(SIGNERS) && !rootGetters.isOwnerAdmin(orgId)) {
        return !checkRole(roles, [USER_ROLES.ADMIN]);
      }
      return rootGetters.hasRole(LEAD_ROLES) && orgId === rootState.user.organization_id;
    },
  }
};
