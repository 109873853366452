import {MINISTRY_OF_CULTURE_ABBREVIATION} from '@/utils/constants';

export const USER_ROLES = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  MANAGER: 3,
  CHIEF: 4,
  ACCOUNTING_OPERATOR: 5,
  OPERATOR: 6,
  RESTORER: 7,
  EXPERT: 8,
  ANALYST: 9,
  LIMITED_OPERATOR: 10,
  RIGHT_TO_SIGN: 11,
  ADMIN_UTCD: 12,
  ANALYST_UTCD: 13,
  API_INTEGRATOR: 14,
  MUSEUM_MKIP: 15,
  TRANSFER_MKIP: 16,
  1: `Експерт ${MINISTRY_OF_CULTURE_ABBREVIATION}`,
  2: 'Керівник музею',
  3: 'Право підпису документів',
  4: 'Головний зберігач',
  5: 'Оператор спецобліку',
  6: 'Оператор-обліковець',
  7: 'Реставратор',
  8: 'Експерт',
  9: `Спеціаліст ${MINISTRY_OF_CULTURE_ABBREVIATION}`,
  10: 'Оператор з обмеженими правами',
  11: 'Право підпису предмета (додаткова роль для оператора)',
  12: 'Експерт УЦКД',
  13: 'Аналітик УЦКД',
  14: 'API-інтегратор',
  15: `Музейний відділ ${MINISTRY_OF_CULTURE_ABBREVIATION}`,
  16: `Відділ переміщень ${MINISTRY_OF_CULTURE_ABBREVIATION}`
};

export const ROLES_OPTIONS = Object.entries(USER_ROLES)
  .filter(([key]) => key > 0 && ![USER_ROLES.MANAGER, USER_ROLES.RESTORER, USER_ROLES.EXPERT]
    .includes(+key));

export const USER_STATUS = {
  INACTIVE: 1,
  CONSIDERATION: 2,
  ACTIVE: 3,
  1: 'Неактивний',
  2: 'На розгляді',
  3: 'Активний',
};

export const MKIP_ROLES = [USER_ROLES.ANALYST, USER_ROLES.SUPER_ADMIN];
export const MKIP_TRANSFER_ROLES = [USER_ROLES.MUSEUM_MKIP, USER_ROLES.TRANSFER_MKIP];
export const FULL_MKIP_ROLES = [...MKIP_TRANSFER_ROLES, ...MKIP_ROLES];
export const UTCD_ROLES = [USER_ROLES.ADMIN_UTCD, USER_ROLES.ANALYST_UTCD];
export const SUPER_ADMIN_ROLES = [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN_UTCD];

export const SIGNERS = [USER_ROLES.CHIEF];
export const LEAD_ROLES = [USER_ROLES.ADMIN, USER_ROLES.CHIEF];
export const DEFAULT_OPERATORS = [USER_ROLES.ACCOUNTING_OPERATOR, USER_ROLES.OPERATOR];
export const DEFAULT_MUSEUM_ROLES = [...LEAD_ROLES, ...DEFAULT_OPERATORS];
export const OPERATORS = [...DEFAULT_OPERATORS, USER_ROLES.ACCOUNTING_OPERATOR];
export const SIGNERS_AND_OPERATORS = [USER_ROLES.CHIEF, ...DEFAULT_OPERATORS];
export const SIGNERS_AND_ALL_OPERATORS = [USER_ROLES.CHIEF, ...OPERATORS];

/**
 * @param {[]} ownedRoles
 * @param {[]} targetRoles
 * @returns {boolean | undefined}
 */
export const checkRole = (ownedRoles, targetRoles) => {
  return ownedRoles?.some((role) => targetRoles.includes(role.id));
};
