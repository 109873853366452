import {ClientMixin, urls} from '@/api/client';

export class Classifications extends ClientMixin {
  /** @param {object} getParams optional GET params */
  list = async (getParams = {}) => this.api.get(urls.classifications.list, getParams);

  /** @param {number} classificationId */
  detail = async (classificationId) => this.api.get(urls.classifications.detail.format({classificationId}));

  /**
   * @param {number} classificationId
   * @param {object} data
   */
  put = async (classificationId, data) => {
    return this.api.put(urls.classifications.detail.format({classificationId}), data);
  }
  /** @param {object} data */
  post = async (data) => this.api.post(urls.classifications.list, data);

  /** @param {number} classificationId */
  destroy = async (classificationId) => this.api.delete(urls.classifications.detail.format({classificationId}));

  /**
   * @param {number} classificationId
   * @param {number} characteristicId
   */
  addCharacteristic = async (classificationId, characteristicId) => {
    return this.api.post(urls.classifications.addCharacteristic.format({classificationId, characteristicId}));
  }
  /**
   * @param {number} classificationId
   * @param {number} characteristicId
   */
  removeCharacteristic = async (classificationId, characteristicId) => {
    return this.api.delete(urls.classifications.removeCharacteristic.format({classificationId, characteristicId}));
  }
}

export class Characteristics extends ClientMixin {
  /** @param {object} getParams */
  list = async (getParams = {}) => this.api.get(urls.characteristics.list, getParams);

  /** @param {number} characteristicId */
  detail = async (characteristicId) => {
    return this.api.get(urls.characteristics.detail.format({characteristicId}));
  }

  /**
   * @param {number} characteristicId
   * @param {object} data
   */
  put = async (characteristicId, data) => {
    return this.api.put(urls.characteristics.detail.format({characteristicId}), data);
  }

  /** @param {object} data */
  post = async (data) => this.api.post(urls.characteristics.list, data);

  /** @param {number} characteristicId */
  destroy = async (characteristicId) => {
    return this.api.delete(urls.characteristics.detail.format({characteristicId}));
  }
}

export class Materials extends ClientMixin {
  /** @param {object} getParams */
  list = async (getParams = {}) => {
    return this.api.get(urls.materials.list, getParams);
  }

  /** @param {number} materialId */
  detail = async (materialId) => {
    return this.api.get(urls.materials.detail.format({materialId}));
  }
  /**
   * @param {number} materialId
   * @param {object} data
   */
  put = async (materialId, data) => {
    return this.api.put(urls.materials.detail.format({materialId}), data);
  }

  /** @param {object} data */
  post = async (data) => this.api.post(urls.materials.list, data);

  /** @param {number} materialId */
  destroy = async (materialId) => this.api.delete(urls.materials.detail.format({materialId}));

  /**
   * @param {number} materialId
   * @param {object} getParams
   */
  techniques = async (materialId, getParams = {}) => {
    return this.api.get(urls.materials.techniques.format({materialId}), getParams);
  }
  /**
   * @param {number} materialId
   * @param {number} techniqueId
   */
  addTechnique = async (materialId, techniqueId) => {
    return this.api.post(urls.materials.addTechnique.format({materialId, techniqueId}));
  }
  /**
   * @param {number} materialId
   * @param {number} techniqueId
   */
  removeTechnique = async (materialId, techniqueId) => {
    return this.api.delete(urls.materials.removeTechnique.format({materialId, techniqueId}));
  }
}

export class Techniques extends ClientMixin {
  /** @param {object} getParams */
  list = async (getParams = {}) => this.api.get(urls.techniques.list, getParams);

  /** @param {number} techniqueId */
  detail = async (techniqueId) => {
    return this.api.get(urls.techniques.detail.format({techniqueId}));
  }

  /**
   * @param {number} techniqueId
   * @param {object} data
   */
  put = async (techniqueId, data) => {
    return this.api.put(urls.techniques.detail.format({techniqueId}), data);
  }

  /** @param {object} data */
  post = async (data) => this.api.post(urls.techniques.list, data);

  /** @param {number} techniqueId */
  destroy = async (techniqueId) => this.api.delete(urls.techniques.detail.format({techniqueId}));
}


