import {ref} from 'vue';
import {onBeforeRouteLeave, useRouter} from 'vue-router';
import routerNames, {LIST_ROUTE_PATH} from '@/router/routerNames';
import {DEFAULT_EXHIBIT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT} from '@/utils/constants';
import {routeMatcher} from '@/utils/utils';
import FormEscapeModal from '@/components/modal/ConfirmModal/FormEscapeModal';

export const useLinkTo = () => {
  const router = useRouter();
  /** @param {string} name */
  const linkTo = (name) => {
    if (routeMatcher(router.options.history.state.back, LIST_ROUTE_PATH[name])) {
      return router.options.history.state.back;
    }
    const limit = name !== routerNames.EXHIBITS.list
      ? DEFAULT_PAGE_LIMIT
      : DEFAULT_EXHIBIT_PAGE_LIMIT;
    return {name, query: {page: 1, limit}};
  };
  return {linkTo, router};
};

/** @param {string} name */
export const useReturnTo = (name) => {
  const router = useRouter();
  const returnPrevious = () => {
    if (routeMatcher(router.options.history.state.back, LIST_ROUTE_PATH[name])) {
      return router.push(router.options.history.state.back);
    }
    return router.push({name});
  };
  return {returnPrevious};
};

/**
 * @param {object} routerCategory
 * @param {number | null} detailId
 * @param {Ref<boolean> | object} isEdited
 */
export const useReturnPrevious = (routerCategory, detailId = null, isEdited = {}) => {
  const router = useRouter();
  const navigateTo = ref(null);
  const canLeaveRoute = ref(false);
  const showEditConfirmModal = ref(false);

  const goListRoute = () => {
    navigateTo.value = null;
    if (routeMatcher(router.options.history.state.back, LIST_ROUTE_PATH[routerCategory.list])) {
      return router.push(router.options.history.state.back);
    }
    return router.push({name: routerCategory.list});
  };

  const goDetailRoute = async () => {
    canLeaveRoute.value = true;
    if (!detailId) {
      return;
    }
    await router.replace({name: routerCategory.detail, params: {id: detailId}});
  };

  const handleEscape = () => {
    canLeaveRoute.value = true;
    if (!navigateTo.value) {
      return goListRoute();
    }
    return router.push(navigateTo.value);
  };

  onBeforeRouteLeave((to, from, next) => {
    if (!canLeaveRoute.value && isEdited.value) {
      navigateTo.value = to;
      showEditConfirmModal.value = true;
      return;
    }
    next();
  });

  return {
    router,
    showEditConfirmModal,
    navigateTo,
    canLeaveRoute,
    goListRoute,
    goDetailRoute,
    handleEscape,
    FormEscapeModal
  };
};
