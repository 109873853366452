const routerNames = Object.freeze({
  AUTH: Object.freeze({
    home: 'home',
    root: 'root',
    login: 'login',
    resetPassword: 'resetPassword'
  }),
  EXHIBITS: Object.freeze({
    list: 'exhibits',
    detail: 'exhibits:detail',
    change: 'exhibits:change',
  }),
  ORGANIZATIONS: Object.freeze({
    list: 'organizations',
    detail: 'organizations:detail',
    my: 'organizations:my',
    connect: 'organizations:connect'
  }),
  USERS: Object.freeze({
    list: 'users',
    detail: 'users:detail',
    change: 'users:change',
    profile: 'users:profile',
  }),
  CLASSIFICATIONS: Object.freeze({
    list: 'classifications',
    create: 'classifications:create',
    detail: 'classifications:detail',
    change: 'classifications:change',
  }),
  CHARACTERISTICS: Object.freeze({
    list: 'characteristics',
    create: 'characteristics:create',
    detail: 'characteristics:detail',
    change: 'characteristics:change',
  }),
  MATERIALS: Object.freeze({
    list: 'materials',
    create: 'materials:create',
    detail: 'materials:detail',
    change: 'materials:change',
  }),
  TECHNIQUES: Object.freeze({
    list: 'techniques',
    create: 'techniques:create',
    detail: 'techniques:detail',
    change: 'techniques:change',
  }),
  LINEAR_REFERENCES: Object.freeze({
    list: 'linearReferences',
    create: 'linearReferences:create',
    detail: 'linearReferences:detail',
    change: 'linearReferences:change',
  }),
  COLLECTIONS: Object.freeze({
    list: 'collections',
    create: 'collections:create',
    detail: 'collections:detail',
    change: 'collections:change',
  }),
  STORAGE_GROUPS: Object.freeze({
    list: 'storageGroups',
    create: 'storageGroups:create',
    detail: 'storageGroups:detail',
    change: 'storageGroups:change',
  }),
  PERSONS: Object.freeze({
    list: 'persons',
    create: 'persons:create',
    detail: 'persons:detail',
    change: 'persons:change',
  }),
  STORAGE_LOCATIONS: Object.freeze({
    list: 'storageLocations',
    create: 'storageLocations:create',
    detail: 'storageLocations:detail',
    change: 'storageLocations:change',
  }),
  TRANSFER_STATEMENTS: Object.freeze({
    list: 'transferRequests',
    detail: 'transferRequests:detail',
    change: 'transferRequests:change',
  }),
  COORDINATOR_STATEMENTS: Object.freeze({
    list: 'coordinatorStatements',
    detail: 'coordinatorStatements:detail',
    change: 'coordinatorStatements:change',
  }),
  TRANSFERS: Object.freeze({
    list: 'transfers',
    detail: 'transfers:detail',
    change: 'transfers:change',
  }),
  EXHIBITIONS: Object.freeze({
    list: 'exhibitions',
    detail: 'exhibitions:detail',
    change: 'exhibitions:change',
  }),
});

export const LIST_ROUTE_PATH = Object.freeze(Object.fromEntries([
  [routerNames.EXHIBITS.list, 'exhibits'],
  [routerNames.CLASSIFICATIONS.list, 'classifications'],
  [routerNames.CHARACTERISTICS.list, 'characteristics'],
  [routerNames.TECHNIQUES.list, 'techniques'],
  [routerNames.MATERIALS.list, 'materials'],
  [routerNames.ORGANIZATIONS.list, 'organizations'],
  [routerNames.USERS.list, 'users'],
  [routerNames.LINEAR_REFERENCES.list, 'linear-references'],
  [routerNames.COLLECTIONS.list, 'collections'],
  [routerNames.STORAGE_GROUPS.list, 'storage-groups'],
  [routerNames.PERSONS.list, 'persons'],
  [routerNames.STORAGE_LOCATIONS.list, 'storage-locations'],
  [routerNames.TRANSFER_STATEMENTS.list, 'transfer-statements'],
  [routerNames.COORDINATOR_STATEMENTS.list, 'coordinator-statements'],
  [routerNames.EXHIBITIONS.list, 'exhibitions'],
  [routerNames.TRANSFERS.list, 'transfers'],
]));

export default routerNames;
