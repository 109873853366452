import {createRouter, createWebHistory} from 'vue-router';
import routerNames from '@/router/routerNames';
import store from '@/store';
import exhibit from '@/router/routes/exhibit';
import management from '@/router/routes/management';
import classifiers from '@/router/routes/classifiers';
import references from '@/router/routes/references';
import documents from '@/router/routes/documents';

const routes = [
  {
    path: '/home',
    name: routerNames.AUTH.home,
    component: () => import('@/views/HomeView'),
    meta: {
      permission: ['hasMinCultRole', 'hasUtcdRole'],
      title: 'Домашня сторінка',
      fullContent: true,
    }
  },
  {
    path: '/',
    name: routerNames.AUTH.root,
    redirect: () => {
      return store.getters.hasMinCultRole || store.getters.hasUtcdRole
        ? {name: routerNames.AUTH.home}
        : {name: routerNames.EXHIBITS.list};
    }
  },
  {
    path: '/login',
    name: routerNames.AUTH.login,
    component: () => import('@/views/Landing/LoginView'),
    meta: {title: 'Логін', authRoute: true}
  },
  {
    path: '/reset-password/:token?',
    name: routerNames.AUTH.resetPassword,
    component: () => import('@/views/Landing/PasswordResetView'),
    meta: {title: 'Скидання паролю', authRoute: true}
  },
  ...exhibit,
  ...management,
  ...classifiers,
  ...references,
  ...documents
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.state.user.id;
  if (isAuthenticated && !store.state.user?.status) {
    await store.dispatch('getUser');
  }
  const authRoute = to.matched.some((record) => record.meta.authRoute);
  switch (true) {
    case isAuthenticated && authRoute:
      next({name: routerNames.AUTH.root});
      break;
    case !isAuthenticated && !authRoute:
      next({name: routerNames.AUTH.login, query: {message: 'login'}});
      break;
    case isAuthenticated && !store.getters.isActiveUser && !to.matched
      .some((record) => record.meta.inactiveUserRoute):
      store.state.user.organization_id
        ? next({name: routerNames.ORGANIZATIONS.my})
        : next({name: routerNames.ORGANIZATIONS.connect});
      break;
    case isAuthenticated && to.matched.some((record) => record.meta.permission):
      if (Array.isArray(to.meta.permission)) {
        to.meta.permission.some((perm) => store.getters[perm]) ? next() : next(from);
        break;
      }
      store.getters[to.meta.permission] ? next() : next(from);
      break;
    default: next();
  }
});
export default router;
