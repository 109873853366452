import {ClientMixin, urls} from '@/api/client';

export class TransferStatements extends ClientMixin {
  /** @param {object} query */
  list = async (query) => this.api.get(urls.transferStatements.list, query);

  /** @param {object} data */
  create = async (data) => this.api.post(urls.transferStatements.list, data);

  /** @param {number} id */
  detail = async (id) => this.api.get(urls.transferStatements.detail.format({id}));

  /**
   * @param {number} id
   * @param {object} data
   */
  patch = async (id, data) => {
    return this.api.put(urls.transferStatements.detail.format({id}), data);
  }

  /** @param {number} id */
  destroy = async (id) => this.api.delete(urls.transferStatements.detail.format({id}));

  /** @param {number} id */
  getSignData = async (id) => this.api.get(urls.transferStatements.signData.format({id}));

  /**
   *  @param {number} id
   *  @param {object} data
   */
  sign = async (id, data) => this.api
    .post(urls.transferStatements.sign.format({id}), data);

  /**
   * @param {number} id
   * @param {FormData} data
   */
  createFile = async (id, data) => {
    return this.api.post(urls.transferStatements.file.format({id}), data);
  }

  /**
   * @param {number} id
   * @param {FormData} data
   */
  sendFiles = async (id, data) => {
    return this.api.post(urls.transferStatements.sendFiles.format({id}), data);
  }

  /**
   * @param {number} id
   * @param {object} query
   */
  exhibits = async (id, query) => this.api
    .get(urls.transferStatements.exhibits.format({id}), query);

  /**
   * @param {number} id
   * @param {number} personId
   */
  setPerson = async (id, personId) => this.api
    .post(urls.transferStatements.person.format({id, personId}));

  /**
   * @param {number} id
   * @param {number} personId
   */
  removePerson = async (id, personId) => this.api
    .delete(urls.transferStatements.person.format({id, personId}));

  /**
   * @param {number} id
   * @param {number} exhibitId
   * @param {{price_in_currency: Number}} data
   */
  upsertExhibitRelation = async (id, exhibitId, data) => this.api
    .post(urls.transferStatements.exhibitsAction.format({id, exhibitId}), data);

  /**
   * @param {number} id
   * @param {number} exhibitId
   */
  removeExhibitRelation = async (id, exhibitId) => this.api
    .delete(urls.transferStatements.exhibitsAction.format({id, exhibitId}));
}

export class CoordinatorStatements extends ClientMixin {
  /** @param {object} query */
  list = async (query) => this.api.get(urls.coordinatorStatements.list, query);

  create = async () => this.api.post(urls.coordinatorStatements.list);

  /** @param {number} id */
  detail = async (id) => this.api.get(urls.coordinatorStatements.detail.format({id}));

  /**
   * @param {number} id
   * @param {object} data
   */
  patch = async (id, data) => this.api
    .put(urls.coordinatorStatements.detail.format({id}), data);

  /** @param {number} id */
  destroy = async (id) => this.api.delete(urls.coordinatorStatements.detail.format({id}));

  /** @param {number} id */
  getSignData = async (id) => this.api.get(urls.coordinatorStatements.signData.format({id}));

  /**
   *  @param {number} id
   *  @param {object} data
   */
  sign = async (id, data) => this.api
    .post(urls.coordinatorStatements.sign.format({id}), data);

  /**
   * @param {number} id
   * @param {FormData} data
   */
  createFile = async (id, data) => {
    return this.api.post(urls.coordinatorStatements.file.format({id}), data);
  }

  /**
   * @param {number} id
   * @param {FormData} data
   */
  sendFiles = async (id, data) => {
    return this.api.post(urls.coordinatorStatements.sendFiles.format({id}), data);
  }

  /**
   * @param {number} id
   * @param {object} query
   */
  exhibits = async (id, query) => this.api
    .get(urls.coordinatorStatements.exhibits.format({id}), query);

  /**
   * @param {number} id
   * @param {number} personId
   */
  setPerson = async (id, personId) => this.api
    .post(urls.coordinatorStatements.person.format({id, personId}));

  /**
   * @param {number} id
   * @param {number} personId
   */
  removePerson = async (id, personId) => this.api
    .delete(urls.coordinatorStatements.person.format({id, personId}));
}

export class Exhibitions extends ClientMixin {
  /** @param {object} query */
  list = async (query) => this.api.get(urls.exhibitions.list, query);

  create = async () => this.api.post(urls.exhibitions.list);

  /** @param {number} id */
  detail = async (id) => this.api.get(urls.exhibitions.detail.format({id}));

  /**
   * @param {number} id
   * @param {object} data
   */
  patch = async (id, data) => this.api
    .put(urls.exhibitions.detail.format({id}), data);

  /** @param {number} id */
  destroy = async (id) => this.api.delete(urls.exhibitions.detail.format({id}));

  /**
   * @param {number} id
   * @param {object} query
   */
  exhibits = async (id, query) => this.api
    .get(urls.exhibitions.exhibits.format({id}), query);

  /**
   * @param {number} id
   * @param {number} statementId
   */
  setTransferStatement = async (id, statementId) => this.api
    .post(urls.exhibitions.transferStatements.format({id, statementId}));

  /**
   * @param {number} id
   * @param {number} statementId
   */
  removeTransferStatement = async (id, statementId) => this.api
    .delete(urls.exhibitions.transferStatements.format({id, statementId}));

  /**
   * @param {number} id
   * @param {number} statementId
   */
  setCoordStatement = async (id, statementId) => this.api
    .post(urls.exhibitions.coordStatements.format({id, statementId}));

  /**
   * @param {number} id
   * @param {number} statementId
   */
  removeCoordStatement = async (id, statementId) => this.api
    .delete(urls.exhibitions.coordStatements.format({id, statementId}));

  /**
   * @param {number} id
   * @param {FormData} data
   */
  createImage = async (id, data) => this.api
    .post(urls.exhibitions.image.format({id}), data);
}

export class Transfers extends ClientMixin {
  /** @param {object} query */
  list = async (query) => this.api.get(urls.transfers.list, query);

  /**
   * @param {object} data
   */
  create = async (data) => this.api.post(urls.transfers.list, data);

  /** @param {number} id */
  detail = async (id) => this.api.get(urls.transfers.detail.format({id}));

  /**
   * @param {number} id
   * @param {object} data
   */
  patch = async (id, data) => this.api
    .put(urls.transfers.detail.format({id}), data);

  /** @param {number} id */
  destroy = async (id) => this.api.delete(urls.transfers.detail.format({id}));

  /**
   * @param {number} id
   * @param {object} query
   */
  exhibits = async (id, query) => this.api
    .get(urls.transfers.exhibits.format({id}), query);

  /**
   * @param {number} id
   * @param {FormData} data
   */
  createFile = async (id, data) => {
    return this.api.post(urls.transfers.file.format({id}), data);
  }

  /**
   * @param {number} id
   * @param {number} personId
   */
  setPerson = async (id, personId) => this.api
    .post(urls.transfers.person.format({id, personId}));

  /**
   * @param {number} id
   * @param {number} personId
   */
  removePerson = async (id, personId) => this.api
    .delete(urls.transfers.person.format({id, personId}));

  /**
   * @param {number} id
   * @param {number} exhibitId
   * @param {{price_in_currency: Number}} data
   */
  setExhibit = async (id, exhibitId, data) => this.api
    .post(urls.transfers.exhibit.format({id, exhibitId}), data);

  /**
   * @param {number} id
   * @param {number} exhibitId
   */
  removeExhibit = async (id, exhibitId) => this.api
    .delete(urls.transfers.exhibit.format({id, exhibitId}));
}

export class Files extends ClientMixin {
  /**
   * @param {number} id
   * @param {object} data
   */
  patch = async (id, data) => this.api.put(urls.file.detail.format({id}), data);

  /** @param {number} id */
  destroy = async (id) => this.api.delete(urls.file.detail.format({id}));
}
