export const DEFAULT_PAGE_LIMIT = 10;
export const DEFAULT_EXHIBIT_PAGE_LIMIT = 20;
export const FILTER_ERROR = 'Поле має містити принаймні 3 символи';
export const EMAIL_REGEXP = new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$');
export const DATE_REGEX = new RegExp(
  '(0[1-9]|1[0-9]|2[0-9]|3[0-1])(-|\\.|\\/)(0[1-9]|[12]\\d|3[01])(-|\\.|\\/)([12]\\d{3})'
);
export const URL_PATTERN = new RegExp(
  '(\\b(https?|ftp|file):\\/\\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])', 'ig'
);
export const MAX_IMAGE_SIZE = 10240; //kb
export const MAX_POSSIBLE_IMAGE_SIZE = 1048576; // kb
export const MAX_CROP_IMAGE_SIZE = 20480; // kb
export const DEFAULT_QUERY_ATTRS = ['sort_by', 'order', 'page', 'limit'];
export const DOCUMENT_FILE_ACCEPT = '.pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png';
export const IS_REQUIRED_ERROR = 'Значення є обов\'язковим!';
export const MINISTRY_OF_CULTURE_ABBREVIATION = 'МКСК';

export const NOTIFICATIONS = {
  processing: {text: 'Обробка...', type: 'processing', svg: 'alert'},
  apiError: {text: 'Сталася помилка обробки. Будь ласка, спробуйте пізніше.'},
  error: {text: 'Помилка!', svg: 'alert_reject'},
  rejected: {text: 'Відхилено!', svg: 'alert_reject'},
  copied: {text: 'Скопійовано!', type: 'success', svg: 'alert_success'},
  saved: {text: 'Збережено!', type: 'success', svg: 'alert_success'},
  deleted: {text: 'Видалено!', type: 'success', svg: 'alert_success'},
  sign: (item) => ({text: `${item} підписано`, type: 'success', svg: 'alert_success'}),
  signTaxNumberError: (item) => ({text: `Підпис ${item} можливий тільки співробітником музею`}),
  addToObject: {text: 'Чудово! :items додані до :object :name', type: 'success', svg: 'alert_success'},
  referenceExhibitsError: {text: 'Помилка обробки об\'єктів довідника', svg: 'alert_reject'},
  createdCollection: {text: 'Колекція створена', type: 'success', svg: 'alert_success'},
  createdGroup: {text: 'Група зберігання створена', type: 'success', svg: 'alert_success'},
  createdLocation: {text: 'Місце зберігання створено', type: 'success', svg: 'alert_success'},
  fileDownloadError: {text: 'Помилка вивантаження файла'},
  imageDownloadError: {text: 'Помилка вивантаження зображення', svg: 'alert_reject'},
  infoSaved: {text: 'Інформацію збережено', type: 'success', svg: 'alert_success'},
  informationTransferred: {text: 'Інформацію передано', type: 'success', svg: 'alert_success'},
  informationTransferError: {text: 'Помилка передачі даних. Спробуйте пізніше'},
  apiKeyError: {text: 'АРІ ключ не додано або він застарів'},
  emailSendSuccess: {text: 'Лист успішно відправлено!', type: 'success', svg: 'alert_success'},
  emailSendError: {text: 'Лист не відправлено, спробуйте пізніше'},
  dataGovUaInfo: {
    text: 'Інформацію надіслано. Портал відкритих даних може довго обробляти запити. Будь ласка, зачекайте 15 хвилин,' +
      ' потім оновіть сайт <a href="https://data.gov.ua/" class="underline" target="_blank">https://data.gov.ua</a>, ' +
      'щоб перевірити, чи з\'явилася інформація.',
    type: 'warn',
    timeout: 15000
  },
  exhibits: {
    searchErr: {text: 'Поле має містити принаймні 2 символи'}
  },
  defaultImageError: {
    text: 'Неможливо встановити зображення більше 20 МБ основним, занадто великий розмір зображення',
    svg: 'alert_reject'
  },
  landing: {
    signToken: {text: 'Помилка отримання токену для підпису, будь ласка спробуйте ще раз'},
    auth: {text: 'Для початку роботи необхідно ввійти в систему'},
    nonFields: {text: 'Будь ласка, заповніть обов’язкові поля'},
    processing: {text: 'Будь ласка, надайте згоду на обробку даних'},
    apiError: {text: 'Помилка логіну чи паролю'},
    passwordSuccess: {text: 'Пароль успішно змінено!', type: 'success'}
  },
  user: {
    signRightsRoleError: {
      text: 'Роль “Право підпису предмета (додаткова роль для оператора)” можна додавати тільки в поєднанні ' +
        'з ролями операторів',
    },
    mkipTransferRoles: {text: `Роль :roles можна додавати тільки в поєднанні з роллю Спеціаліст ${MINISTRY_OF_CULTURE_ABBREVIATION}`},
    rolesConflictError: {text: 'Дане поєднання ролей заборонено: :roles'},
    rolesError: {text: 'Користувач повинен мати принаймні одну роль'},
    positionError: {text: 'Поле "Посада" є обов\'язковим для заповнення'},
    roleRemoveError: (roleName) => {
      return {text: `Доступ на видалення ролі "${roleName}" обмежено для вашого облікового запису`};
    },
    apiErrorGroups: {text: 'Сталася помилка обробки груп зберігання. Будь ласка, спробуйте пізніше.'},
    success: {text: 'Погоджено!', type: 'success', svg: 'alert_success'},
  }
};

export const QUERY_ATTR_NAMES_TO_UK = Object.freeze({
  exhibits: Object.freeze({
    owner_id: 'Оперативне управління',
    keeper_id: 'Місце розташування (музей)',
    keeper_person_id: 'Місце розташування (особа)',
    classification_id: 'Класифікація',
    search: 'Пошук',
    storage_groups: 'Групи зберігання',
    collections: 'Колекція',
    conditions: 'Стан збереження',
    state: 'Статус предмета',
    status: 'Підпис',
    is_deleted: 'Показати видалені'
  }),
  classifiers: Object.freeze({
    name: 'Назва',
    name_EN: 'Назва En',
    description: 'Опис',
    organization_id: 'Адміністратор групи',
    characteristic_id: 'Класифікаційні ознаки',
    type: 'Тип ознаки',
    is_precious_metal: 'Дорогоцінний метал',
    is_gemstone: 'Коштовне каміння'
  }),
  organizations: Object.freeze({
    name: 'Назва',
    tax_number: 'Номер ЄДРПОУ',
    region_fact: 'Адміністративно-територіальна одиниця (фактична)',
    address_fact: 'Адреса (фактична)',
    organization_id: 'Головний музей',
    only_trashed: 'Статус',
    is_subordination: `Підпорядкування ${MINISTRY_OF_CULTURE_ABBREVIATION}`
  }),
  persons: Object.freeze({
    type: 'Тип особи',
    search: 'Пошук (Назва, Юрисдикція)',
    organization_id: 'Адміністратор',
    is_author: 'Показати авторів'
  }),
  references: Object.freeze({
    name: 'Назва',
    description: 'Опис',
    organization_id: 'Адміністратор'
  }),
  users: Object.freeze({
    name: 'ПІБ',
    organization_id: 'Музеї',
    role_id: 'Ролі',
    tax_number: 'РНОКПП',
    email: 'Email',
    phone: 'Телефон',
    position_id: 'Посада',
    storage_group_id: 'Групи зберігання',
    status: 'Статус'
  }),
  transfers: Object.freeze({
    document_date: 'Дата документа',
    status: 'Статус',
    purpose: 'Мета',
    declarant_person_id: 'Передано (особа)',
    declarant_organization_id: 'Передано (музей)',
    recipient_person_id: 'Прийнято (особа)',
    recipient_organization_id: 'Прийнято (музей)',
    exhibits: 'Предмети',
    transfer_statement_id: 'Заява на переміщення',
    coordinator_statement_id: 'Заява координатора',
    is_deleted: 'Позначені на видалення документи',
  }),
  get transferStatements() {
    return Object.freeze({
      ...this.transfers,
      recipient_person_id: 'Приймаюча сторона (особа)',
      recipient_organization_id: 'Приймаюча сторона (музей)',
      coordinator_person_id: 'Координатор (особа)',
      coordinator_organization_id: 'Координатор (музей)',
      organization_id: 'Заявник',
      search: 'Пошук',
      transfer_statements: 'Заява на переміщення',
      declarant_person_id: 'Заявник (особа)',
      declarant_organization_id: 'Заявник (музей)',
    });
  },
  exhibitions: Object.freeze({
    name: 'Назва',
    description: 'Опис',
    term_start_date: 'Період проведення з',
    term_end_date: 'Період проведення до',
    organization_id: 'Адміністратор',
    venue_organization_id: 'Місце проведення (музей)',
    venue_person_id: 'Місце проведення (особа)',
    exhibits: 'Предмети',
  })
});
