import {SIGNERS_AND_OPERATORS, FULL_MKIP_ROLES, DEFAULT_MUSEUM_ROLES, USER_ROLES} from '@/utils/user';
import {STATEMENT_STATUSES, TRANSFERS_STATUSES, TRANSFER_STATEMENT_KINDS} from '@/utils/enums';

export const transferStatements = {
  namespaced: true,
  getters: {
    createPermission: (state, getters, rootState, rootGetters) => {
      return rootGetters.isMuseumEmployee && rootGetters.hasRole(SIGNERS_AND_OPERATORS);
    },
    modulePermissions: (state, getters, rootState, rootGetters) => ({
      organization_id, recipient_organization_id, coordinator_organization_id, status, kind
    }) => {
      const mkipDocsRoles = rootGetters.hasAllRoles([USER_ROLES.ANALYST, USER_ROLES.TRANSFER_MKIP]);
      const museumDocsRoles = rootGetters
        .hasRole(DEFAULT_MUSEUM_ROLES) && organization_id === rootState.user.organization_id;
      const rolesPerm = rootGetters.hasRole(SIGNERS_AND_OPERATORS);
      return {
        get detail() {
          if (rootGetters.hasRole([...FULL_MKIP_ROLES, USER_ROLES.ADMIN_UTCD])) {
            return true;
          }
          return rootGetters.hasRole(DEFAULT_MUSEUM_ROLES) && [organization_id, recipient_organization_id,
            coordinator_organization_id].includes(rootState.user.organization_id);
        },
        get change() {
          return [STATEMENT_STATUSES.DRAFT, STATEMENT_STATUSES.PENDING_MUSEUM]
            .includes(status) && rolesPerm && organization_id === rootState.user.organization_id;
        },
        get sign() {
          return rootGetters.isOwnerAdmin(organization_id) && STATEMENT_STATUSES.PENDING_MUSEUM === status;
        },
        get processing() {
          if (status !== STATEMENT_STATUSES.PENDING_MKIP) {
            return false;
          }
          return rootGetters.hasRole([USER_ROLES.ANALYST]) && rootGetters
            .hasRole([USER_ROLES.MUSEUM_MKIP, USER_ROLES.TRANSFER_MKIP]);
        },
        get record() {
          if (status !== STATEMENT_STATUSES.APPROVAL) {
            return false;
          }
          return rolesPerm && [organization_id, recipient_organization_id,
            coordinator_organization_id].includes(rootState.user.organization_id);
        },
        get document() {
          if (kind !== TRANSFER_STATEMENT_KINDS.CERTIFICATE) {
            return false;
          }
          return museumDocsRoles;
        },
        get certificate() {
          if (status !== STATEMENT_STATUSES.PENDING_MKIP) {
            return false;
          }
          return mkipDocsRoles;
        },
        get listDoc() {
          return mkipDocsRoles || museumDocsRoles;
        },
        get sendEmail() {
          if (status !== STATEMENT_STATUSES.APPROVAL) {
            return false;
          }
          return rootGetters.hasRole(DEFAULT_MUSEUM_ROLES) && organization_id === rootState.user.organization_id;
        },
        get actions() {
          return [
            this.record,
            this.change,
            this.document,
            this.certificate,
            this.listDoc,
            this.sendEmail
          ].some((perm) => perm);
        }
      };
    },
  }
};

export const transfers = {
  namespaced: true,
  getters: {
    modulePermissions: (state, getters, rootState, rootGetters) => ({
      organization_id, recipient_organization_id, declarant_organization_id, status
    }) => {
      const mkipRolesPermission = rootGetters.hasAllRoles([USER_ROLES.ANALYST, USER_ROLES.TRANSFER_MKIP]);
      const museumRolePermissions = rootGetters
        .hasRole(SIGNERS_AND_OPERATORS) && organization_id === rootState.user.organization_id;
      const museumDocsFullPermission = rootGetters.hasRole(DEFAULT_MUSEUM_ROLES) && [organization_id,
        recipient_organization_id, declarant_organization_id].includes(rootState.user.organization_id);
      return {
        get detail() {
          if (rootGetters.hasMinCultRole || rootGetters.isUtcdAdmin) {
            return true;
          }
          return museumDocsFullPermission;
        },
        get change() {
          if (status === TRANSFERS_STATUSES.ACTIVE) {
            return false;
          }
          if (!organization_id) {
            return mkipRolesPermission;
          }
          return museumRolePermissions;
        },
        get approve() {
          if (status !== TRANSFERS_STATUSES.PENDING) {
            return false;
          }
          if (!organization_id) {
            return mkipRolesPermission;
          }
          return rootGetters.isOwnerAdmin(organization_id);
        },
        get act() {
          if (!organization_id) {
            return mkipRolesPermission;
          }
          return museumDocsFullPermission;
        },
        get actSpecial() {
          if (!organization_id) {
            return mkipRolesPermission;
          }
          return rootGetters.hasRole([USER_ROLES.ADMIN, USER_ROLES.CHIEF, USER_ROLES.ACCOUNTING_OPERATOR]) && [
            organization_id, recipient_organization_id, declarant_organization_id
          ].includes(rootState.user.organization_id);
        },
        get actions() {
          return [this.change, this.actSpecial, this.act].some((perm) => perm);
        },
      };
    },
  }
};

export const coordStatements = {
  namespaced: true,
  getters: {
    createPermission: (state, getters, rootState, rootGetters) => {
      if (rootGetters.isMuseumEmployee) {
        return rootGetters.hasRole(SIGNERS_AND_OPERATORS);
      }
      return rootGetters.hasAllRoles([USER_ROLES.ANALYST, USER_ROLES.TRANSFER_MKIP]);
    },
    modulePermissions: (state, getters, rootState, rootGetters) => ({
      organization_id, recipient_organization_id, declarant_organization_id, status
    }) => {
      const mkipRolesPermission = rootGetters.hasAllRoles([USER_ROLES.ANALYST, USER_ROLES.TRANSFER_MKIP]);
      const museumRolesPermission = rootGetters.hasRole(SIGNERS_AND_OPERATORS);
      const museumDocsRolesPermissions = rootGetters.hasRole(DEFAULT_MUSEUM_ROLES);
      return {
        get change() {
          if (!organization_id) {
            return mkipRolesPermission && status === STATEMENT_STATUSES.DRAFT;
          }
          return [STATEMENT_STATUSES.DRAFT, STATEMENT_STATUSES.PENDING_MUSEUM]
            .includes(status) && museumRolesPermission && organization_id === rootState.user.organization_id;
        },
        get sign() {
          if (!organization_id || STATEMENT_STATUSES.PENDING_MUSEUM !== status) {
            return false;
          }
          return rootGetters.isOwnerAdmin(organization_id);
        },
        get processing() {
          if (status !== STATEMENT_STATUSES.PENDING_MKIP) {
            return false;
          }
          return mkipRolesPermission;
        },
        get record() {
          if (status !== STATEMENT_STATUSES.APPROVAL) {
            return false;
          }
          if (!organization_id) {
            return mkipRolesPermission;
          }
          return museumRolesPermission && [organization_id, recipient_organization_id, declarant_organization_id]
            .includes(rootState.user.organization_id);
        },
        get document() {
          if (!organization_id) {
            return mkipRolesPermission;
          }
          return museumDocsRolesPermissions && organization_id === rootState.user.organization_id;
        },
        get certificate() {
          if (status !== STATEMENT_STATUSES.PENDING_MKIP) {
            return false;
          }
          return mkipRolesPermission;
        },
        get listDoc() {
          return mkipRolesPermission || (museumDocsRolesPermissions && rootState.user
            .organization_id === organization_id);
        },
        get sendEmail() {
          if (status !== STATEMENT_STATUSES.APPROVAL) {
            return false;
          }
          return rootGetters.hasRole(DEFAULT_MUSEUM_ROLES) && organization_id === rootState.user.organization_id;
        },
        get actions() {
          return [
            this.record,
            this.change,
            this.document,
            this.certificate,
            this.listDoc,
            this.sendEmail
          ].some((perm) => perm);
        }
      };
    },
  }
};

export const exhibitions = {
  namespaced: true,
  getters: {
    listPermission: (state, getters, rootState, rootGetters) => {
      return rootGetters.documentsModuleViewPermission || rootGetters.hasUtcdRole;
    },
    createPermission: (state, getters, rootState, rootGetters) => {
      if (rootGetters.isMuseumEmployee) {
        return rootGetters.hasRole([USER_ROLES.CHIEF]);
      }
      return rootGetters.hasMinCultRole;
    },
    modulePermissions: (state, getters, rootState, rootGetters) => ({
      organization_id
    }) => {
      const museumEmployeePermission = rootGetters
        .hasRole([USER_ROLES.CHIEF]) && organization_id === rootState.user.organization_id;
      return {
        get shortDetail() {
          if (rootGetters.hasUtcdRole || rootGetters.hasMinCultRole) {
            return true;
          }
          return rootGetters.hasRole(DEFAULT_MUSEUM_ROLES);
        },
        get fullDetail() {
          if (rootGetters.hasUtcdRole || rootGetters.hasMinCultRole) {
            return true;
          }
          return rootGetters.hasRole(DEFAULT_MUSEUM_ROLES) && organization_id === rootState.user.organization_id;
        },
        get change() {
          if (!organization_id) {
            return rootGetters.hasMinCultRole;
          }
          return museumEmployeePermission;
        },
        get remove() {
          if (!organization_id) {
            return rootGetters.isSuperAdmin;
          }
          return museumEmployeePermission;
        },
      };
    },
  }
};
