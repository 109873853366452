<script setup>
import AppHeader from '@/components/common/AppHeader';
import AppSidebar from '@/components/common/AppSidebar';
import routerNames from '@/router/routerNames';

import {onMounted, watch, computed} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';

const store = useStore(), route = useRoute();
const isLogin = computed(() => [routerNames.AUTH.login, routerNames.AUTH.resetPassword].includes(route.name));

watch(route, (to) => {
  document.title = to.meta.title;
  const data = JSON.parse(window.localStorage.getItem('mc-data'));
  if (data?.user?.id && data.user.id !== store.state.user.id) {
    store.dispatch('getUser');
  }
}, {immediate: true});

onMounted(() => {
  window.addEventListener('load', () => store.commit('setReady', true));
});
</script>

<template>
  <AppHeader :is-login="isLogin" />
  <div class="app-wrapper">
    <AppSidebar v-if="!isLogin"/>
    <article class="wrapper__content" :class="{'full-content': route.meta.fullContent}">
      <RouterView/>
    </article>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
