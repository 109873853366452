import {ClientMixin, urls} from '@/api/client';

export class StorageGroups extends ClientMixin {

  /** @param {object} data */
  create = async (data = {}) => this.api.post(urls.storageGroups.list, data);

  /**
   * @param {object} data
   * @param {number} groupId
   */
  patch = async (groupId, data = {}) => {
    return this.api.put(urls.storageGroups.detail.format({groupId}), data);
  }

  /** @param {object} getParams */
  list = async (getParams = {}) => this.api.get(urls.storageGroups.list, getParams);

  /** @param {number} groupId */
  detail = async (groupId) => this.api.get(urls.storageGroups.detail.format({groupId}));

  /** @param {number} groupId */
  destroy = async (groupId) => this.api.delete(urls.storageGroups.detail.format({groupId}));
}

export class StorageLocations extends ClientMixin {
  /** @param {object} data */
  create = async (data = {}) => this.api.post(urls.storageLocations.list, data);

  /**
   * @param {object} data
   * @param {number} locationId
   */
  patch = async (locationId, data = {}) => {
    return this.api.put(urls.storageLocations.detail.format({locationId}), data);
  }

  /** @param {object} getParams */
  list = async (getParams = {}) => this.api.get(urls.storageLocations.list, getParams);

  /** @param {number} locationId */
  detail = async (locationId) => {
    return this.api.get(urls.storageLocations.detail.format({locationId}));
  }

  /** @param {number} locationId */
  destroy = async (locationId) => {
    return this.api.delete(urls.storageLocations.detail.format({locationId}));
  }
}


export class Persons extends ClientMixin {
  /** @param {object} getParams */

  list = async (getParams = {}) => this.api.get(urls.persons.list, getParams);

  count = async () => this.api.get(urls.persons.count);

  /** @param {number} personId */
  detail = async (personId) => this.api.get(urls.persons.detail.format({personId}));

  /** @param {object} data */
  create = async (data = {}) => this.api.post(urls.persons.list, data);

  /**
   * @param {number} personId
   * @param {object} data
   */
  put = async (personId, data) => this.api.put(urls.persons.detail.format({personId}), data);

  /** @param {number} personId */
  delete = async (personId) => this.api.delete(urls.persons.detail.format({personId}));

  /** @param {number} personId */
  copy = async (personId) => this.api.post(urls.persons.copy.format({personId}));
}

export class Collections extends ClientMixin {
  /** @param {object} data */
  create = async (data = {}) => this.api.post(urls.collections.list, data);

  /** @param {object} getParams optional GET params */
  list = async (getParams = {}) => this.api.get(urls.collections.list, getParams);

  /**
   * @param {number} collectionId
   * @param {object} data
   */
  patch = async (collectionId, data) => {
    return this.api.put(urls.collections.detail.format({collectionId}), data);
  }

  /** @param {number} collectionId */
  detail = async (collectionId) => this.api.get(urls.collections.detail.format({collectionId}));

  /** @param {number} collectionId */
  destroy = async (collectionId) => this.api.delete(urls.collections.detail.format({collectionId}));
}

export class LinearReferences extends ClientMixin {
  /** @param {object} getParams */
  list = async (getParams = {}) => this.api.get(urls.linearReferences.list, getParams);

  /** @param {number} referenceId */
  detail = async (referenceId) => this.api.get(urls.linearReferences.detail.format({referenceId}));

  /**
   * @param {number} referenceId
   * @param {object} data
   */
  put = async (referenceId, data) => {
    return this.api.put(urls.linearReferences.detail.format({referenceId}), data);
  }

  /** @param data {object} */
  post = async (data) => this.api.post(urls.linearReferences.list, data);

  /** @param {number} referenceId */
  delete = async (referenceId) => this.api.delete(urls.linearReferences.detail.format({referenceId}));
}

export class LinearReferencesValue extends ClientMixin {
  /** @param {object} getParams */
  list = async (getParams = {}) => this.api.get(urls.linearReferencesValue.list, getParams);

  /** @param {number} valueId */
  detail = async ( valueId) => {
    return this.api.get(urls.linearReferencesValue.detail.format({valueId}));
  }

  /**
   * @param {number} valueId
   * @param {object} data
   */
  put = async (valueId, data) => {
    return this.api.put(urls.linearReferencesValue.detail.format({valueId}), data);
  }

  /** @param data {object} */
  post = async (data) => this.api.post(urls.linearReferencesValue.list, data);

  /** @param {number} valueId */
  delete = async (valueId) => this.api.delete(urls.linearReferencesValue.detail.format({valueId}));
}
