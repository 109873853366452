import routerNames from '@/router/routerNames';
export default [
  {
    path: '/classifications',
    name: routerNames.CLASSIFICATIONS.list,
    component: () => import('@/views/Classifiers/Classification'),
    meta: {
      title: 'Класифікації',
      isClassifier: true,
      categoryRoot: routerNames.CLASSIFICATIONS.list,
      fullContent: true
    },
  },
  {
    path: '/classifications/create',
    name: routerNames.CLASSIFICATIONS.create,
    component: () => import('@/views/Classifiers/Classification/Create'),
    meta: {
      title: 'Створення класифікатора',
      isClassifier: true,
      permission: 'thesaurus/createPermission',
      categoryRoot: routerNames.CLASSIFICATIONS.list
    },
  },
  {
    path: '/classifications/:id',
    name: routerNames.CLASSIFICATIONS.detail,
    component: () => import('@/views/Classifiers/Classification/Detail'),
    meta: {
      title: 'Класифікатор',
      isClassifier: true,
      categoryRoot: routerNames.CLASSIFICATIONS.list
    },
  },
  {
    path: '/classifications/:id/update',
    name: routerNames.CLASSIFICATIONS.change,
    component: () => import('@/views/Classifiers/Classification/Update'),
    meta: {
      title: 'Редагування класифікатора',
      isClassifier: true,
      categoryRoot: routerNames.CLASSIFICATIONS.list
    },
  },
  {
    path: '/characteristics',
    name: routerNames.CHARACTERISTICS.list,
    component: () => import('@/views/Classifiers/Characteristics'),
    meta: {
      title: 'Класифікаційні ознаки',
      isClassifier: true,
      categoryRoot: routerNames.CHARACTERISTICS.list,
      fullContent: true
    },
  },
  {
    path: '/characteristics/create',
    name: routerNames.CHARACTERISTICS.create,
    component: () => import('@/views/Classifiers/Characteristics/Create'),
    meta: {
      title: 'Створення класифікаційної ознаки',
      isClassifier: true,
      permission: 'characteristics/createPermission',
      categoryRoot: routerNames.CHARACTERISTICS.list
    },
  },
  {
    path: '/characteristics/:id',
    name: routerNames.CHARACTERISTICS.detail,
    component: () => import('@/views/Classifiers/Characteristics/Detail'),
    meta: {
      title: 'Класифікаційна ознака',
      isClassifier: true,
      categoryRoot: routerNames.CHARACTERISTICS.list
    },
  },
  {
    path: '/characteristics/:id/update/',
    name: routerNames.CHARACTERISTICS.change,
    component: () => import('@/views/Classifiers/Characteristics/Update'),
    meta: {
      title: 'Редагування класифікаційної ознаки',
      isClassifier: true,
      categoryRoot: routerNames.CHARACTERISTICS.list
    },
  },
  {
    path: '/techniques',
    name: routerNames.TECHNIQUES.list,
    component: () => import('@/views/Classifiers/Techniques/List'),
    meta: {
      title: 'Техніки виконання',
      isClassifier: true,
      categoryRoot: routerNames.TECHNIQUES.list,
      fullContent: true
    },
  },
  {
    path: '/techniques/create',
    name: routerNames.TECHNIQUES.create,
    component: () => import('@/views/Classifiers/Techniques/Create'),
    meta: {
      title: 'Створення техніки виконання',
      isClassifier: true,
      permission: 'thesaurus/createPermission',
      categoryRoot: routerNames.TECHNIQUES.list
    },
  },
  {
    path: '/techniques/:id',
    name: routerNames.TECHNIQUES.detail,
    component: () => import('@/views/Classifiers/Techniques/Detail'),
    meta: {
      title: 'Техніка виконання',
      isClassifier: true,
      categoryRoot: routerNames.TECHNIQUES.list
    },
  },
  {
    path: '/techniques/:id/update/',
    name: routerNames.TECHNIQUES.change,
    component: () => import('@/views/Classifiers/Techniques/Update'),
    meta: {
      title: 'Редагування техніки виконання',
      isClassifier: true,
      categoryRoot: routerNames.TECHNIQUES.list
    },
  },
  {
    path: '/materials',
    name: routerNames.MATERIALS.list,
    component: () => import('@/views/Classifiers/Material'),
    meta: {
      title: 'Матеріали',
      isClassifier: true,
      categoryRoot: routerNames.MATERIALS.list,
      fullContent: true
    },
  },
  {
    path: '/materials/create',
    name: routerNames.MATERIALS.create,
    component: () => import('@/views/Classifiers/Material/Create'),
    meta: {
      title: 'Створення матеріалу',
      isClassifier: true,
      permission: 'thesaurus/createPermission',
      categoryRoot: routerNames.MATERIALS.list
    },
  },
  {
    path: '/materials/:id',
    name: routerNames.MATERIALS.detail,
    component: () => import('@/views/Classifiers/Material/Detail'),
    meta: {
      title: 'Матеріал',
      isClassifier: true,
      categoryRoot: routerNames.MATERIALS.list
    },
  },
  {
    path: '/materials/:id/update/',
    name: routerNames.MATERIALS.change,
    component: () => import('@/views/Classifiers/Material/Update'),
    meta: {
      title: 'Редагування матеріалу',
      isClassifier: true,
      categoryRoot: routerNames.MATERIALS.list
    },
  },
];
