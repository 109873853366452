import {SIGNERS, SIGNERS_AND_ALL_OPERATORS, SUPER_ADMIN_ROLES} from '@/utils/user';

export const characteristics = {
  namespaced: true,
  getters: {
    createPermission: (state, getters, rootState, rootGetters) => rootGetters.hasRole(SIGNERS_AND_ALL_OPERATORS),
    deletePermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (!orgId || rootState.user.organization_id !== orgId) {
        return false;
      }
      return rootGetters.hasRole(SIGNERS);
    },
    changePermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (!orgId || rootState.user.organization_id !== orgId) {
        return false;
      }
      return rootGetters.hasRole(SIGNERS_AND_ALL_OPERATORS);
    },
  }
};

export const thesaurus = {
  namespaced: true,
  getters: {
    createPermission: (state, getters, rootState, rootGetters) => [rootGetters.hasMinCultRole,
      rootGetters.isUtcdAdmin, rootGetters.hasRole(SIGNERS_AND_ALL_OPERATORS)].some((perm) => perm),

    deletePermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (!orgId && (rootGetters.hasRole(SUPER_ADMIN_ROLES))) {
        return true;
      }
      if (!orgId || rootState.user.organization_id !== orgId) {
        return false;
      }
      return rootGetters.hasRole(SIGNERS);
    },
    changePermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (!orgId && (rootGetters.hasMinCultRole || rootGetters.isUtcdAdmin)) {
        return true;
      }
      if (!orgId || rootState.user.organization_id !== orgId) {
        return false;
      }
      return rootGetters.hasRole(SIGNERS_AND_ALL_OPERATORS);
    }
  }
};

