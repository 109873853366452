<template>
  <div
    class="modal"
    v-if="showModal"
    @click="onHide"
  >
    <div
      class="modal-wrapper confirm-modal d-inline-flex no-wrap"
      :class="[centered ? 'w-40 h-50': 'w-30']"
    >
      <div class="modal__content" @click.stop="$emit('content-click')">
        <div class="confirm-content mx-auto" :class="[centered ? 'w-60': 'w-80']">
          <img :class="svgClass" :src="getSvg(svgIcon)" alt="">
          <p class="title fs-20 fw-500">{{title}}</p>
          <p class="sub-title fs-12" v-if="subtitle">{{subtitle}}</p>
          <slot name="form" />
          <div class="confirm-buttons" :class="{'w-80': centered}">
            <button
              :class="okVariant"
              v-if="okButton"
              @click="$emit('ok')"
            >
              <slot name="ok-button" />
            </button>
            <button
              :class="rejectVariant"
              v-if="rejectButton"
              @click="$emit('reject')"
            >
              <slot name="reject-button" />
            </button>
            <button
              :class="canselVariant"
              v-if="canselButton"
              @click="onCansel"
            >
              <slot name="cansel" />
            </button>
          </div>
        </div>
      </div>
      <div class="close-btn">
        <button class="modal-close-btn" @click="onHide" />
      </div>
    </div>
  </div>
</template>

<script>
import {getSvg} from '@/utils/utils';

export default {
  name: 'ConfirmModal',
  emits: ['update:show-modal', 'ok', 'cansel', 'reject', 'content-click', 'hide'],
  props: {
    showModal: {type: Boolean, required: true},
    svgIcon: {type: String, required: true},
    svgClass: {type: String, default: ''},
    title: {type: String, default: ''},
    subtitle: {type: String, default: ''},
    okButton: {type: Boolean, default: false},
    rejectButton: {type: Boolean, default: false},
    canselButton: {type: Boolean, default: false},
    okVariant: {type: [Array, String], default: 'btn-black'},
    rejectVariant: {type: String, default: 'btn-reject'},
    canselVariant: {type: [Array, String], default: 'btn-grey-f4'},
    centered: {type: Boolean, default: false},
  },
  methods: {
    onCansel() {
      this.$emit('cansel');
      this.$emit('update:show-modal', false);
    },
    onHide() {
      this.$emit('hide');
      this.$emit('update:show-modal', false);
    },
    getSvg
  }
};
</script>

