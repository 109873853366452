<script setup>
import {reactive} from 'vue';
import {useLinkTo} from '@/hooks/useNavigation';
import routerNames from '@/router/routerNames';

const dropdowns = reactive({
  classification: false,
  reference: false,
  referenceSystem: false,
  documents: false,
});
const {linkTo} = useLinkTo();
</script>

<template>
  <aside class="wrapper__sidebar fs-13">
    <ul class="sidebar-ul">
      <li class="sidebar-el">
        <div class="active-link" :class="{'active': $route.meta.isMuseumFund}">
          <i class="i-aspect-ratio"/>
        </div>
      </li>
      <li class="sidebar-el">
        <div class="active-link" :class="{'active': $route.meta.isClassifier}">
          <i class="i-view-three-columns"/>
        </div>
      </li>
      <li class="sidebar-el">
        <div class="active-link" :class="{'active': $route.meta.isReference}">
          <i class="i-book"/>
        </div>
      </li>
      <li class="sidebar-el">
        <div class="active-link" :class="{'active': $route.meta.isManagement}">
          <i class="i-side-flag"/>
        </div>
      </li>
      <li class="sidebar-el" v-if="$store.getters['exhibitions/listPermission']">
        <div class="active-link" :class="{'active': $route.meta.isDocument}">
          <i class="i-file i-24 black"/>
        </div>
      </li>
    </ul>
    <div class="sidebar__expanded">
      <ul class="sidebar-ul">
        <li class="sidebar-el">
          <router-link :to="linkTo(routerNames.EXHIBITS.list)">
            <div class="active-link text-nowrap" :class="{'active': $route.meta.isMuseumFund}">
              <i class="i-aspect-ratio"/> Музейний фонд
            </div>
          </router-link>
        </li>
        <li class="sidebar-el" :class="{'bg-grey-f4': dropdowns.classification}">
          <div
            :class="{'active': $route.meta.isClassifier}"
            class="active-link"
            @click="dropdowns.classification = !dropdowns.classification"
          >
            <i class="i-view-three-columns"/> <span class="dropdown-note">Тезауруси</span>
            <button class="sidebar-dropdown-btn mx-right" :class="{'rotated': dropdowns.classification}"/>
          </div>
          <div class="dropdown-links" v-show="dropdowns.classification">
            <router-link class="dropdown-link" :to="linkTo(routerNames.CLASSIFICATIONS.list)">
              Класифікації
            </router-link>
            <router-link class="dropdown-link" :to="linkTo(routerNames.CHARACTERISTICS.list)">
              Класифікаційні <br>ознаки
            </router-link>
            <router-link class="dropdown-link" :to="linkTo(routerNames.TECHNIQUES.list)">
              Техніки
            </router-link>
            <router-link class="dropdown-link" :to="linkTo(routerNames.MATERIALS.list)">
              Матеріали
            </router-link>
          </div>
        </li>
        <li class="sidebar-el" :class="{'bg-grey-f4': dropdowns.reference}">
          <div
            class="active-link"
            :class="{'active': $route.meta.isReference}"
            @click="dropdowns.reference = !dropdowns.reference"
          >
            <i class="i-book"/> <span class="dropdown-note">Довідники</span>
            <button class="sidebar-dropdown-btn mx-right" :class="{'rotated': dropdowns.reference} "/>
          </div>
          <div class="dropdown-links" v-show="dropdowns.reference">
            <div :class="{'bg-grey-e9': dropdowns.referenceSystem}">
              <div
                class="d-flex align-center justify-between no-wrap dropdown-el pt-8"
                style="padding-right: 0"
                @click="dropdowns.referenceSystem = !dropdowns.referenceSystem"
              >
                <span class="dropdown-note">Системні</span>
                <button class="sidebar-dropdown-btn mx-right" :class="{'rotated': dropdowns.referenceSystem}"/>
              </div>
              <div class="sys-links" v-show="dropdowns.referenceSystem">
                <router-link class="dropdown-link text-grey" :to="linkTo(routerNames.COLLECTIONS.list)">
                  Колекції
                </router-link>
                <router-link class="dropdown-link text-grey" :to="linkTo(routerNames.STORAGE_GROUPS.list)">
                  Групи зберігання
                </router-link>
                <router-link class="dropdown-link text-grey" :to="linkTo(routerNames.STORAGE_LOCATIONS.list)">
                  Місця зберігання
                </router-link>
                <router-link class="dropdown-link text-grey pb-8" :to="linkTo(routerNames.PERSONS.list)">
                  Особи
                </router-link>
              </div>
            </div>
            <router-link class="dropdown-link" :to="linkTo(routerNames.LINEAR_REFERENCES.list)">
              Лінійні
            </router-link>
          </div>
        </li>
        <li class="sidebar-el">
          <router-link
            :to="linkTo($store.getters.isActiveUser ? routerNames.ORGANIZATIONS.list : routerNames.ORGANIZATIONS.my)"
          >
            <div class="active-link" :class="{'active': $route.meta.isManagement}">
              <i class="i-side-flag"/> Музеї
            </div>
          </router-link>
        </li>
        <li
          class="sidebar-el"
          v-if="$store.getters['exhibitions/listPermission']"
          :class="{'bg-grey-f4': dropdowns.documents}"
        >
          <div
            :class="{'active': $route.meta.isDocument}"
            class="active-link"
            @click="dropdowns.documents = !dropdowns.documents"
          >
            <i class="i-file black i-24"/> <span class="dropdown-note">Документи</span>
            <button class="sidebar-dropdown-btn mx-right" :class="{'rotated': dropdowns.documents}"/>
          </div>
          <div class="dropdown-links" v-show="dropdowns.documents">
            <router-link class="dropdown-link" :to="linkTo(routerNames.EXHIBITIONS.list)">
              Виставки за <br> кордоном
            </router-link>
            <template v-if="$store.getters.documentsModuleViewPermission">
              <router-link class="dropdown-link" :to="linkTo(routerNames.TRANSFER_STATEMENTS.list)">
                Заяви на <br> переміщення
              </router-link>
              <router-link class="dropdown-link" :to="linkTo(routerNames.COORDINATOR_STATEMENTS.list)">
                Заяви координатора
              </router-link>
              <router-link class="dropdown-link" :to="linkTo(routerNames.TRANSFERS.list)">
                Переміщення предметів
              </router-link>
            </template>
          </div>
        </li>
      </ul>
    </div>
  </aside>
</template>
