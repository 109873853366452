import routerNames from '@/router/routerNames';

export default [
  {
    path: '/transfer-statements',
    name: routerNames.TRANSFER_STATEMENTS.list,
    component: () => import('@/views/Documents/TransferStatements/ListView'),
    meta: {
      title: 'Заяви на переміщення',
      categoryRoot: routerNames.TRANSFER_STATEMENTS.list,
      isDocument: true,
      fullContent: true,
      permission: 'documentsModuleViewPermission'
    }
  },
  {
    path: '/transfer-statements/:id',
    name: routerNames.TRANSFER_STATEMENTS.detail,
    component: () => import('@/views/Documents/TransferStatements/DetailView'),
    meta: {
      title: 'Перегляд заяви на переміщення',
      categoryRoot: routerNames.TRANSFER_STATEMENTS.list,
      isDocument: true,
    }
  },
  {
    path: '/transfer-statements/:id/update',
    name: routerNames.TRANSFER_STATEMENTS.change,
    component: () => import('@/views/Documents/TransferStatements/UpdateView'),
    meta: {
      title: 'Редагування заяви на переміщення',
      categoryRoot: routerNames.TRANSFER_STATEMENTS.list,
      isDocument: true,
    }
  },
  {
    path: '/coordinator-statements',
    name: routerNames.COORDINATOR_STATEMENTS.list,
    component: () => import('@/views/Documents/CoordinatorStatements/ListView'),
    meta: {
      title: 'Заяви координатора',
      categoryRoot: routerNames.COORDINATOR_STATEMENTS.list,
      isDocument: true,
      fullContent: true,
      permission: 'documentsModuleViewPermission'
    }
  },
  {
    path: '/coordinator-statements/:id',
    name: routerNames.COORDINATOR_STATEMENTS.detail,
    component: () => import('@/views/Documents/CoordinatorStatements/DetailView'),
    meta: {
      title: 'Перегляд заяви координатора',
      categoryRoot: routerNames.COORDINATOR_STATEMENTS.list,
      isDocument: true,
    }
  },
  {
    path: '/coordinator-statements/:id/update',
    name: routerNames.COORDINATOR_STATEMENTS.change,
    component: () => import('@/views/Documents/CoordinatorStatements/UpdateView'),
    meta: {
      title: 'Редагування заяви координатора',
      categoryRoot: routerNames.COORDINATOR_STATEMENTS.list,
      isDocument: true,
    }
  },
  {
    path: '/exhibitions',
    name: routerNames.EXHIBITIONS.list,
    component: () => import('@/views/Documents/Exhibitions/ListView'),
    meta: {
      title: 'Виставки за кордоном',
      categoryRoot: routerNames.EXHIBITIONS.list,
      isDocument: true,
      fullContent: true,
      permission: 'exhibitions/listPermission'
    }
  },
  {
    path: '/exhibitions/:id',
    name: routerNames.EXHIBITIONS.detail,
    component: () => import('@/views/Documents/Exhibitions/DetailView'),
    meta: {
      title: 'Перегляд виставки за кордоном',
      categoryRoot: routerNames.EXHIBITIONS.list,
      isDocument: true,
    }
  },
  {
    path: '/exhibitions/:id/change',
    name: routerNames.EXHIBITIONS.change,
    component: () => import('@/views/Documents/Exhibitions/UpdateView'),
    meta: {
      title: 'Редагування виставки за кордоном',
      categoryRoot: routerNames.EXHIBITIONS.list,
      isDocument: true,
    }
  },
  {
    path: '/transfers',
    name: routerNames.TRANSFERS.list,
    component: () => import('@/views/Documents/Transfers/ListView'),
    meta: {
      title: 'Переміщення предметів',
      categoryRoot: routerNames.TRANSFERS.list,
      isDocument: true,
      fullContent: true,
      permission: 'documentsModuleViewPermission'
    }
  },
  {
    path: '/transfers/:id',
    name: routerNames.TRANSFERS.detail,
    component: () => import('@/views/Documents/Transfers/DetailView'),
    meta: {
      title: 'Перегляд переміщення предметів',
      categoryRoot: routerNames.TRANSFERS.list,
      isDocument: true,
      permission: 'documentsModuleViewPermission'
    }
  },
  {
    path: '/transfers/:id/change',
    name: routerNames.TRANSFERS.change,
    component: () => import('@/views/Documents/Transfers/UpdateView'),
    meta: {
      title: 'Редагування переміщення предметів',
      categoryRoot: routerNames.TRANSFERS.list,
      isDocument: true,
      permission: 'documentsModuleViewPermission'
    }
  },
];
