import {DEFAULT_OPERATORS, LEAD_ROLES, SIGNERS, SIGNERS_AND_ALL_OPERATORS, SUPER_ADMIN_ROLES} from '@/utils/user';

export const storageGroups = {
  namespaced: true,
  getters: {
    viewPermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (rootGetters.hasMinCultRole || rootGetters.hasUtcdRole) {
        return true;
      }
      if (orgId && rootState.user.organization_id !== orgId) {
        return false;
      }
      return !!rootState.user?.roles.length;
    },
    createPermission: (state, getters, rootState, rootGetters) => rootGetters.hasRole(SIGNERS),
    changePermission: (state, getters, rootState) => (orgId) => {
      if (!orgId || rootState.user.organization_id !== orgId) {
        return false;
      }
      return getters.createPermission;
    },
  },
};

export const collections = {
  namespaced: true,
  getters: {
    viewPermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (rootGetters.hasMinCultRole || rootGetters.hasUtcdRole) {
        return true;
      }
      if (orgId && rootState.user.organization_id !== orgId) {
        return false;
      }
      return !!rootState.user?.roles.length;
    },

    createPermission: (state, getters, rootState, rootGetters) => [rootGetters.hasMinCultRole,
      rootGetters.hasRole(SIGNERS), rootGetters.isUtcdAdmin].some((el) => el),

    changePermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (!orgId && (rootGetters.hasMinCultRole || rootGetters.isUtcdAdmin)) {
        return true;
      }
      if (!orgId) {
        return false;
      }
      return rootGetters.hasRole(SIGNERS) && rootState.user.organization_id === orgId;
    },

    deletePermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (!orgId && rootGetters.hasRole(SUPER_ADMIN_ROLES)) {
        return true;
      }
      if (!orgId) {
        return false;
      }
      return rootGetters.hasRole(SIGNERS) && rootState.user.organization_id === orgId;
    },

    exportPermission: (state, getters, rootState, rootGetters) => (orgId) => {
      const hasOrganizationPermission = rootGetters.hasRole([...DEFAULT_OPERATORS, ...LEAD_ROLES]);
      if (!orgId) {
        return [hasOrganizationPermission, rootGetters.hasMinCultRole, rootGetters.hasUtcdRole].some((el) => el);
      }
      return hasOrganizationPermission && rootState.user.organization_id === orgId;
    },
  },
};

export const persons = {
  namespaced: true,
  getters: {
    createPermission: (state, getters, rootState, rootGetters) => {
      return [rootGetters.hasMinCultRole, rootGetters.isUtcdAdmin,
        rootGetters.hasRole(SIGNERS_AND_ALL_OPERATORS)].some((el) => el);
    },

    deletePermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (!orgId && (rootGetters.hasRole(SUPER_ADMIN_ROLES))) {
        return true;
      }
      return rootState.user.organization_id === orgId && rootGetters.hasRole(SIGNERS);
    },

    changePermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (!orgId && (rootGetters.hasMinCultRole || rootGetters.isUtcdAdmin)) {
        return true;
      }
      return rootState.user.organization_id === orgId && rootGetters.hasRole(SIGNERS_AND_ALL_OPERATORS);
    },

    copyPermission: (state, getters, rootState, rootGetters) => rootGetters.hasRole(SIGNERS_AND_ALL_OPERATORS),
  },
};

export const linearReferences = {
  namespaced: true,
  getters: {
    createPermission: (state, getters, rootState, rootGetters) => rootGetters.hasRole(SIGNERS_AND_ALL_OPERATORS),

    changePermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (!orgId || rootState.user.organization_id !== orgId) {
        return false;
      }
      return rootGetters.hasRole(SIGNERS_AND_ALL_OPERATORS);
    },

    deletePermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (!orgId || rootState.user.organization_id !== orgId) {
        return false;
      }
      return rootGetters.hasRole(SIGNERS);
    },
  }
};

export const storageLocations = {
  namespaced: true,
  getters: {
    createPermission: (state, getters, rootState, rootGetters) => rootGetters.hasRole(SIGNERS),

    changePermission: (state, getters, rootState, rootGetters) => (orgId) => {
      if (!orgId || rootState.user.organization_id !== orgId) {
        return false;
      }
      return rootGetters.hasRole(SIGNERS);
    }
  }
};
