<script setup>
import {computed} from 'vue';
import api from '@/api';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {getUserName} from '@/utils/utils';
import routerNames from '@/router/routerNames';
import {useLinkTo} from '@/hooks/useNavigation';

defineProps({isLogin: {type: Boolean, required: true}});

const store = useStore(), router = useRouter();
const {linkTo} = useLinkTo();

const homeLink = computed(() => store.getters.hasMinCultRole || store.getters.hasUtcdRole
  ? {name: routerNames.AUTH.home}
  : linkTo(routerNames.EXHIBITS.list));

const logOut = async () => {
  const [, error] = await api.auth.logout();
  if (!error) {
    store.commit('setUser', {});
    await router.push({name: routerNames.AUTH.login});
  }
};
</script>

<template>
  <header class="app-header" :class="{'border-none': isLogin}">
    <router-link :to="homeLink">
      <div class="header__left d-inline-flex align-center gap-4 no-wrap fs-12">
        <div>
          <img src="@/assets/svg/images/ua_trident_v2.svg" alt="">
        </div>
        <div class="header__title">
          <span>Реєстр</span><span>Музейного</span><span>Фонду України</span>
        </div>
      </div>
    </router-link>
    <div class="header__right">
      <div class="header__user fs-12" v-if="!isLogin">
        <div class="d-inline-flex align-center no-wrap">
          <div class="user__short_info">
            <p class="text-grey-b3">{{ store.state.user.id ? getUserName(store.state.user.name, 3) : '-' }}</p>
            <p>{{ store.state.user.organization?.name }}</p>
          </div>
          <div class="user-initials">{{ getUserName(store.state.user.name, 0) }}</div>
        </div>
        <ul class="user__menu">
          <li class="user__link mt-4">
            <router-link
              v-if="!(store.getters.hasUtcdRole || store.getters.hasMinCultRole)"
              :to="{name: routerNames.ORGANIZATIONS.my}"
            >
              Мої музеї
            </router-link>
          </li>
          <li class="user__link">
            <router-link :to="{name: routerNames.USERS.profile}">Мій профайл</router-link>
          </li>
          <li class="user__link pointer" @click="logOut">Вихід</li>
        </ul>
      </div>
    </div>
  </header>
</template>
