import routerNames from '@/router/routerNames';
export default [
  {
    path: '/organizations/',
    name: routerNames.ORGANIZATIONS.list,
    component: () => import('@/views/Management/OrganizationsView'),
    meta: {
      title: 'Музеї',
      isManagement: true,
      categoryRoot: routerNames.ORGANIZATIONS.list,
      fullContent: true
    }
  },
  {
    path: '/organizations/:id',
    name: routerNames.ORGANIZATIONS.detail,
    component: () => import('@/views/Management/OrganizationDetailView'),
    meta: {
      title: 'Перегляд музею',
      isManagement: true,
      categoryRoot: routerNames.ORGANIZATIONS.list,
      fullContent: true
    }
  },
  {
    path: '/my-organizations/',
    name: routerNames.ORGANIZATIONS.my,
    component: () => import('@/views/Management/MyOrganizationsView'),
    meta: {
      title: 'Мої музеї',
      isManagement: true,
      inactiveUserRoute: true,
      categoryRoot: routerNames.ORGANIZATIONS.list,
      fullContent: true
    }
  },
  {
    path: '/organizations/connect',
    name: routerNames.ORGANIZATIONS.connect,
    component: () => import('@/views/Management/OrganizationConnectView'),
    meta: {
      title: 'Підключення до музею',
      isManagement: true,
      inactiveUserRoute: true,
      categoryRoot: routerNames.ORGANIZATIONS.list,
      fullContent: true
    }
  },
  {
    path: '/users/',
    name: routerNames.USERS.list,
    component: () => import('@/views/Management/UsersView'),
    meta: {
      title: 'Користувачі',
      isManagement: true,
      permission: ['hasMinCultRole', 'hasUtcdRole'],
      categoryRoot: routerNames.ORGANIZATIONS.list,
      fullContent: true
    }
  },
  {
    path: '/users/profile',
    name: routerNames.USERS.profile,
    component: () => import('@/views/UserCabinetView'),
    meta: {
      title: 'Мій профайл',
      isManagement: true,
      inactiveUserRoute: true,
      categoryRoot: routerNames.ORGANIZATIONS.list
    }
  },
  {
    path: '/users/:id',
    name: routerNames.USERS.detail,
    component: () => import('@/views/Management/UserDetailView'),
    meta: {
      title: 'Перегляд користувача',
      isManagement: true,
      categoryRoot: routerNames.ORGANIZATIONS.list
    }
  },
  {
    path: '/users/:id/update',
    name: routerNames.USERS.change,
    component: () => import('@/views/Management/UserChangeView'),
    meta: {
      title: 'Погодження користувача',
      isManagement: true,
      categoryRoot: routerNames.ORGANIZATIONS.list
    }
  },
];
