import routerNames from '@/router/routerNames';

export default [
  //linear
  {
    path: '/linear-references',
    name: routerNames.LINEAR_REFERENCES.list,
    component: () => import('@/views/References/LinearReferences/List'),
    meta: {
      title: 'Лінійні довідники',
      isReference: true,
      categoryRoot: routerNames.LINEAR_REFERENCES.list,
      fullContent: true
    }
  },
  {
    path: '/linear-references/create',
    name: routerNames.LINEAR_REFERENCES.create,
    component: () => import('@/views/References/LinearReferences/Create'),
    meta: {
      title: 'Створення лінійного довідника',
      permission: 'linearReferences/createPermission',
      isReference: true,
      categoryRoot: routerNames.LINEAR_REFERENCES.list
    }
  },
  {
    path: '/linear-references/:id',
    name: routerNames.LINEAR_REFERENCES.detail,
    component: () => import('@/views/References/LinearReferences/Detail'),
    meta: {
      title: 'Перегляд лінійного довідника',
      isReference: true,
      categoryRoot: routerNames.LINEAR_REFERENCES.list
    }
  },
  {
    path: '/linear-references/:id/update',
    name: routerNames.LINEAR_REFERENCES.change,
    component: () => import('@/views/References/LinearReferences/Update'),
    meta: {
      title: 'Редагування лінійного довідника',
      isReference: true,
      categoryRoot: routerNames.LINEAR_REFERENCES.list
    }
  },
  // collections
  {
    path: '/collections',
    name: routerNames.COLLECTIONS.list,
    component: () => import('@/views/References/Collections/List'),
    meta: {
      title: 'Колекції музейних предметів',
      isReference: true,
      categoryRoot: routerNames.COLLECTIONS.list,
      fullContent: true
    }
  },
  {
    path: '/collections/create',
    name: routerNames.COLLECTIONS.create,
    component: () => import('@/views/References/Collections/Create'),
    meta: {
      title: 'Створення колекції',
      permission: 'collections/createPermission',
      isReference: true,
      categoryRoot: routerNames.COLLECTIONS.list
    }
  },
  {
    path: '/collections/:id',
    name: routerNames.COLLECTIONS.detail,
    component: () => import('@/views/References/Collections/Detail'),
    meta: {
      title: 'Перегляд колекції',
      isReference: true,
      categoryRoot: routerNames.COLLECTIONS.list
    }
  },
  {
    path: '/collections/:id/update',
    name: routerNames.COLLECTIONS.change,
    component: () => import('@/views/References/Collections/Update'),
    meta: {
      title: 'Редагування колекції',
      isReference: true,
      categoryRoot: routerNames.COLLECTIONS.list
    }
  },
  // storageGroups
  {
    path: '/storage-groups',
    name: routerNames.STORAGE_GROUPS.list,
    component: () => import('@/views/References/StorageGroups/List'),
    meta: {
      title: 'Групи зберігання',
      isReference: true,
      categoryRoot: routerNames.STORAGE_GROUPS.list,
      fullContent: true
    }
  },
  {
    path: '/storage-groups/create',
    name: routerNames.STORAGE_GROUPS.create,
    component: () => import('@/views/References/StorageGroups/Create'),
    meta: {
      title: 'Створення групи зберігання',
      permission: 'storageGroups/createPermission',
      isReference: true,
      categoryRoot: routerNames.STORAGE_GROUPS.list
    }
  },
  {
    path: '/storage-groups/:id',
    name: routerNames.STORAGE_GROUPS.detail,
    component: () => import('@/views/References/StorageGroups/Detail'),
    meta: {
      title: 'Перегляд групи зберігання',
      isReference: true,
      categoryRoot: routerNames.STORAGE_GROUPS.list
    }
  },
  {
    path: '/storage-groups/:id/update',
    name: routerNames.STORAGE_GROUPS.change,
    component: () => import('@/views/References/StorageGroups/Update'),
    meta: {
      title: 'Редагування групи зберігання',
      isReference: true,
      categoryRoot: routerNames.STORAGE_GROUPS.list
    }
  },
  // persons
  {
    path: '/persons',
    name: routerNames.PERSONS.list,
    component: () => import('@/views/References/Persons/List'),
    meta: {
      title: 'Особи',
      isReference: true,
      categoryRoot: routerNames.PERSONS.list,
      fullContent: true
    }
  },
  {
    path: '/persons/create',
    name: routerNames.PERSONS.create,
    component: () => import('@/views/References/Persons/Create'),
    meta: {
      title: 'Створення особи',
      permission: 'persons/createPermission',
      isReference: true,
      categoryRoot: routerNames.PERSONS.list
    }
  },
  {
    path: '/persons/:id',
    name: routerNames.PERSONS.detail,
    component: () => import('@/views/References/Persons/Detail'),
    meta: {
      title: 'Перегляд особи',
      isReference: true,
      categoryRoot: routerNames.PERSONS.list
    }
  },
  {
    path: '/persons/:id/update',
    name: routerNames.PERSONS.change,
    component: () => import('@/views/References/Persons/Update'),
    meta: {
      title: 'Редагування особи',
      isReference: true,
      categoryRoot: routerNames.PERSONS.list
    }
  },
  // storageLocations
  {
    path: '/storage-locations',
    name: routerNames.STORAGE_LOCATIONS.list,
    component: () => import('@/views/References/StorageLocations/List'),
    meta: {
      title: 'Місця зберігання',
      isReference: true,
      categoryRoot: routerNames.STORAGE_LOCATIONS.list,
      fullContent: true
    }
  },
  {
    path: '/storage-locations/create',
    name: routerNames.STORAGE_LOCATIONS.create,
    component: () => import('@/views/References/StorageLocations/Create'),
    meta: {
      title: 'Створення місця зберігання',
      permission: 'storageLocations/createPermission',
      isReference: true,
      categoryRoot: routerNames.STORAGE_LOCATIONS.list
    }
  },
  {
    path: '/storage-locations/:id',
    name: routerNames.STORAGE_LOCATIONS.detail,
    component: () => import('@/views/References/StorageLocations/Detail'),
    meta: {
      title: 'Перегляд місця зберігання',
      isReference: true,
      categoryRoot: routerNames.STORAGE_LOCATIONS.list
    }
  },
  {
    path: '/storage-locations/:id/update',
    name: routerNames.STORAGE_LOCATIONS.change,
    component: () => import('@/views/References/StorageLocations/Update'),
    meta: {
      title: 'Редагування місця зберігання',
      isReference: true,
      categoryRoot: routerNames.STORAGE_LOCATIONS.list
    }
  },
];
