<script setup>
import {getSvg} from '@/utils/utils';
import {useStore} from 'vuex';
import {computed} from 'vue';

const store = useStore();
const notification = computed(() => store.state.notification);
</script>

<template>
<div class="bubble-notification">
  <div
    class="alert fs-12 text-black d-inline-flex align-center no-wrap"
    :class="notification.type"
    v-if="notification?.text">
    <img :src="getSvg(notification?.svg)" alt=""> <span v-html="notification?.text"/>
  </div>
</div>
</template>
