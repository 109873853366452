import qs from 'qs';
import {_axios, _request} from '@/api/client';
import {isEmpty} from 'lodash';
import {Auth, Settings, DataGovUa} from '@/api/categories/sys';
import {
  Exhibits,
  ExhibitNames,
  Numbers,
  Sizes,
  Prices,
  Images,
  Conditions,
  ExhibitEvents,
  Gemstones,
  PreciousMetals,
  ExhibitDescriptions,
  Compositions
} from '@/api/categories/exhibits';
import {
  Organizations,
  Users,
  Positions
} from '@/api/categories/management';
import {
  Classifications,
  Characteristics,
  Techniques,
  Materials
} from '@/api/categories/thesaurus';
import {
  StorageGroups,
  StorageLocations,
  Persons,
  Collections,
  LinearReferences,
  LinearReferencesValue
} from '@/api/categories/references';
import {
  TransferStatements,
  Files,
  CoordinatorStatements,
  Exhibitions,
  Transfers
} from '@/api/categories/documents';

class API {
  constructor() {
    this.organizations = new Organizations(this);
    this.users = new Users(this);
    this.auth = new Auth(this);
    this.positions = new Positions(this);
    this.storageGroups = new StorageGroups(this);
    this.storageLocations = new StorageLocations(this);
    this.persons = new Persons(this);
    this.classifications = new Classifications(this);
    this.collections = new Collections(this);
    this.characteristics = new Characteristics(this);
    this.materials = new Materials(this);
    this.techniques = new Techniques(this);
    this.linearReferences = new LinearReferences(this);
    this.linearReferencesValue = new LinearReferencesValue(this);
    this.exhibits = new Exhibits(this);
    this.exhibitNames = new ExhibitNames(this);
    this.numbers = new Numbers(this);
    this.sizes = new Sizes(this);
    this.prices = new Prices(this);
    this.images = new Images(this);
    this.conditions = new Conditions(this);
    this.events = new ExhibitEvents(this);
    this.gemstones = new Gemstones(this);
    this.preciousMetals = new PreciousMetals(this);
    this.exhibitDescriptions = new ExhibitDescriptions(this);
    this.compositions = new Compositions(this);
    this.transferStatements = new TransferStatements(this);
    this.coordinatorStatements = new CoordinatorStatements(this);
    this.exhibitions = new Exhibitions(this);
    this.transfers = new Transfers(this);
    this.file = new Files(this);
    this.settings = new Settings(this);
    this.dataGovUa = new DataGovUa(this);
  }
  get client() {
    return _axios;
  }

  _request = _request;

  /**
   * @param {string} url
   * @param {object} getParams optional GET params
   * @returns {Promise.<Array.<{response: Promise<import('axios').AxiosResponse<*>>, error: ApiError | null}>>}
   */
  async get(url, getParams = {}) {
    if (!isEmpty(getParams)) {
      url += `?${qs.stringify(getParams, {arrayFormat: 'brackets'})}`;
    }
    return this._request(this.client.get, url);
  }
  /**
   * @param {string} url
   * @param {object | FormData} data
   * @returns {Promise.<Array.<{response: Promise<import('axios').AxiosResponse<*>>, error: ApiError | null}>>}
   */
  async post(url, data = {}) {
    return this._request(this.client.post, url, data);
  }

  /**
   * @param {string} url
   * @param {object | FormData} data
   * @returns {Promise.<Array.<{response: Promise<import('axios').AxiosResponse<*>>, error: ApiError | null}>>}
   */
  async put(url, data) {
    return this._request(this.client.put, url, data);
  }

  /**
   * @param {string} url
   * @returns {Promise.<Array.<{response: Promise<import('axios').AxiosResponse<*>>, error: ApiError | null}>>}
   */
  async delete(url) {
    return this._request(this.client.delete, url);
  }

  /**
   * @param {string} url
   * @param {object} data
   * @returns {Promise.<Array.<{response: Promise<import('axios').AxiosResponse<*>>, error: ApiError | null}>>}
   */
  async archive(url, data) {
    return this._request(this.client.delete, url, {data: data});
  }
}

const api = new API();
export default api;
