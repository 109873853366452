<script setup>
import ConfirmModal from '@/components/modal/ConfirmModal/ConfirmModal';

defineProps({
  showModal: {type: Boolean, required: true},
  title: {type: String, default: 'Ви впевнені, що бажаєте вийти без збереження?'}
});

const emit = defineEmits(['save-changes', 'no-save-escape', 'update:show-modal']);

</script>

<template>
  <ConfirmModal
    svg-icon="edit"
    svg-class="i-64"
    ok-button
    cansel-button
    reject-button
    :title="title"
    :show-modal="showModal"
    @update:show-modal="emit('update:show-modal', $event)"
    @ok="emit('save-changes')"
    @reject="emit('no-save-escape')"
    @cansel="emit('update:show-modal', false)"
  >
    <template #ok-button>Зберегти і вийти</template>
    <template #reject-button>Вийти без збереження</template>
    <template #cansel>Скасувати</template>
  </ConfirmModal>
</template>
