import moment from 'moment';
import {URL_PATTERN} from '@/utils/constants';
import {LIST_ROUTE_PATH} from '@/router/routerNames';

moment.locale('uk');

/**
 * @param {string} date date from backend
 * @param {string} formatter
 * @return {string} Moment date with formatted date repr
 */
export const getDate = (date, formatter = 'DD.MM.YYYY') => {
  if (!date) {
    return '-';
  }
  return moment(date).format(formatter);
};

/**
 * @param {Date} date
 * @returns {string} result for sending data to api
 */
export const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

/**
 * @param {string | Date} date date from backend
 * @return {string} Moment date with formatted date repr
 */
export const getDateTime = (date) => {
  if (!date) {
    return '-';
  }
  return moment(date).format('DD.MM.YYYY HH:mm');
};

/**
 * function for components that get svg icon through props
 * @param {string} icon
 * @return {string} valid path to svg icon
 */
export const getSvg = (icon) => {
  return require(`@/assets/svg/${icon}.svg`);
};

/**
 * get default value instead of empty string
 * @param value
 * @return {*|string}
 */
export const getDefaultValue = (value) => {
  if (!value) {
    return '-';
  }
  return value;
};

/**
 * @param {string} lastName
 * @param {string} firstName
 * @param {number} value
 * @returns {string}
 */
const handleSimplifiedName = (lastName, firstName, value) => {
  switch (value) {
    case 0:
      return `${lastName.charAt(0)}${firstName.charAt(0)}`;
    case 3:
      return `${lastName} ${firstName}`;
    default:
      return `${lastName} ${firstName.charAt(0)}.`;
  }
};

/**
 * @param {string} lastName
 * @param {string} firstName
 * @param {string} middleName
 * @param {number} value
 * @returns {string}
 */
const handleFullName = (lastName, firstName, middleName, value) => {
  switch (value) {
    case 0:
      return `${lastName.charAt(0)}${firstName.charAt(0)}`;
    case 1:
      return `${lastName} ${firstName.charAt(0)}.`;
    case 2:
      return `${lastName} ${firstName.charAt(0)}. ${middleName.charAt(0)}.`;
    case 3:
      return `${lastName} ${firstName}`;
  }
};
/**
 * @description Split and return user's name with different variants
 * 0 - initials, 1 - Zaluzhnyi V., 2 - Zaluzhnyi V. F., 3 - Zaluzhnyi Valery
 * @param {string} name full name of the user << Zaluzhnyi Valery Fedorovych>>
 * @param {number} value
 * @return {*|string} '-' if no name or concatenated string
 */
export const getUserName = (name, value) => {
  if (!name) {
    return '-';
  }
  const [lastName, firstName, middleName] = name.split(' ');
  if (!firstName) {
    return value ? lastName : lastName.charAt(0);
  }
  if (!middleName) {
    return handleSimplifiedName(lastName, firstName, value);
  }
  return handleFullName(lastName, firstName, middleName, value);
};

/**
 * Reset form fields errors
 * @param {object} formErrors
 */
export const resetErrors = (formErrors) => {
  Object.keys(formErrors).forEach((error) => formErrors[error] = '');
};

/**
 * Map dict {id: value} from options to get values by key
 * @param {array} option
 * @param {string} value field name that contains needed value (default -> 'name')
 * @return {object}
 */
export const mapOptions = (option, value = 'name') => {
  return Object.fromEntries(option.map((opt) => {
    return [opt.id, opt[value]];
  }));
};

/**
 * @param {Array} arr
 */
export const mapIds = (arr) => {
  return arr.map((el) => {
    return el.id;
  });
};

/**
 * We need to handle filter in case if we searched something from api, selected result may
 * not be in the options list
 * @param {string} queryAttr
 * @param {object} value
 * @param {boolean} isArrayFilter
 * @param {string} optionsName
 */
export function handleFilter({queryAttr, value, isArrayFilter, optionsName}) {
  if (!value) {
    return ;
  }

  if (!this.options[optionsName].list.find((el) => el.id === value.id)) {
    this.options[optionsName].list.push(value);
  }
  this.setFilter({queryAttr, value: value.id, isArrayFilter});
}

/**
 * @description for compositions api
 * @param {string} value
 * @param {string} attrName
 * @param {object} errors reactive errors object
 */
export const handleQueryInputError = (value, attrName, errors) => {
  if (value?.length >= 3 && errors[attrName]) {
    errors[attrName] = '';
  }
};

/**
 * @param {string | null} before route path
 * @param {string} routeName route path to check
 * @returns {boolean}
 */
export const routeMatcher = (before, routeName) => {
  if (!before) {
    return false;
  }
  return !!before.match(new RegExp(`/(${routeName}|${routeName}/)[?]`));
};

/** @param {string} routeName */
export function returnTo(routeName) {
  this.navigateTo = null;
  if (routeMatcher(this.$router.options.history.state.back, LIST_ROUTE_PATH[routeName])) {
    return this.$router.push(this.$router.options.history.state.back);
  }
  return this.$router.push({name: routeName});
}

/**
 * @param {string} mode
 * @param {object} parent
 */
export function setParent(mode, parent = {}) {
  switch(mode) {
    case 'set':
      this.parentObject = parent;
      this.form.parent_id = parent?.id;
      break;
    case 'remove':
      this.parentObject = null;
      this.form.parent_id = null;
      break;
  }
}

/**
 * @param {Event} event
 * @param {function} emit
 */
export const onScroll = (event, emit) => {
  const {clientHeight, scrollHeight, scrollTop} = event.target;
  if ((scrollHeight - clientHeight - scrollTop) <= 0.5 ) {
    emit('on-scroll');
  }
};

/**
 * @param {string} text
 */
export const convertLinks = (text) => {
  if (!text) {
    return '-';
  }
  return text.replace(URL_PATTERN, '<a href="$1" class="underline" target="_blank">$1</a>');
};

/**
 * @param {object} option
 * @param {Reactive<DropdownOptions>} options
 */
export const checkDropdownOption = (option, options) => {
  if (!options.list.some((item) => item.id === option.id)) {
    options.list.push(option);
  }
};

/**
 * @param {object} data
 * @param {object} instance
 * @returns {boolean}
 */
export const hasChangedData = (data, instance) => {
  let edit = false;
  for (const key in data) {
    if (edit) {
      break;
    }
    if (!data[key] && !instance[key]) {
      continue;
    }
    edit = data[key] !== instance[key];
  }
  return edit;
};

/**
 * @param {string} name
 */
export const normalizeFileName = (name) => name.split('.').slice(0, -1).join('.');

/**
 * @param {object} exhibit
 */
export const getExhibitKeeper = (exhibit) => {
  if (exhibit.keeper) {
    return exhibit.keeper?.name;
  }
  return `<span class="text-clamp">${exhibit.keeper_person?.fullname || '-'}</span>`;
};
