import {
  OPERATORS,
  LEAD_ROLES,
  SIGNERS,
  SIGNERS_AND_ALL_OPERATORS,
  SIGNERS_AND_OPERATORS,
  USER_ROLES
} from '@/utils/user';


export default {
  namespaced: true,
  getters: {
    createPermission: (state, getters, rootState, rootGetters) => {
      if (!rootState.user.organization_id) {
        return false;
      }
      return rootGetters.hasRole(OPERATORS);
    },

    shortViewPermission: (state, getters, rootState, rootGetters) => ({ownerId, keeperId, keeperParentId}) => {
      if (rootGetters.hasMinCultRole || rootGetters.hasUtcdRole) {
        return true;
      }
      if (!rootState.user.organization_id) {
        return false;
      }
      const hasRole = rootGetters.hasRole([USER_ROLES.ADMIN, ...SIGNERS_AND_ALL_OPERATORS]);
      return hasRole && [ownerId, keeperId, keeperParentId].includes(rootState.user.organization_id);
    },

    fullViewPermission: (state, getters, rootState, rootGetters) => ({groupIds, ownerId}) => {
      if (rootGetters.hasMinCultRole || rootGetters.hasUtcdRole || rootGetters.isOwnerAdmin(ownerId)) {
        return true;
      }
      if (rootState.user.organization_id !== ownerId) {
        return false;
      }
      const hasGroup = !groupIds.length || !rootState.user.storage_groups?.length
        ? true
        : rootState.user.storage_groups?.some((group) => groupIds.includes(group.id));
      return rootGetters.hasRole(SIGNERS_AND_ALL_OPERATORS) && hasGroup;
    },

    specialViewPermission: (state, getters, rootState, rootGetters) => ({groupIds, ownerId}) => {
      if ([rootGetters.hasMinCultRole, rootGetters.hasUtcdRole, rootGetters.isOwnerAdmin(ownerId)].some((el) => el)) {
        return true;
      }
      if (ownerId !== rootState.user.organization_id) {
        return false;
      }
      const hasGroup = !groupIds.length || !rootState.user.storage_groups?.length
        ? true
        : rootState.user.storage_groups?.some((group) => groupIds.includes(group.id));
      return rootGetters.hasRole([USER_ROLES.CHIEF, USER_ROLES.ACCOUNTING_OPERATOR]) && hasGroup;
    },

    specialChangePermission: (state, getters, rootState, rootGetters) => ({groupIds, ownerId}) => {
      if (rootState.user.organization_id !== ownerId) {
        return false;
      }
      const hasGroup = !groupIds.length || !rootState.user.storage_groups?.length
        ? true
        : rootState.user.storage_groups?.some((group) => groupIds.includes(group.id));
      return rootGetters.hasRole([USER_ROLES.ACCOUNTING_OPERATOR]) && hasGroup;
    },

    changePermission: (state, getters, rootState, rootGetters) => ({groupIds, ownerId}) => {
      if (rootState.user.organization_id !== ownerId || !Array.isArray(groupIds)) {
        return false;
      }
      const hasOperatorRole = rootGetters.hasRole(OPERATORS);
      const hasGroup = !groupIds.length || !rootState.user.storage_groups?.length
        ? true
        : rootState.user.storage_groups?.some((group) => groupIds.includes(group.id));
      return hasOperatorRole && hasGroup;
    },

    docDownloadPermission: (state, getters, rootState, rootGetters) => ({groupIds, ownerId}) => {
      if (!Array.isArray(groupIds)) {
        return false;
      }
      if (rootGetters.isOwnerAdmin(ownerId)) {
        return true;
      }
      const hasGroup = !groupIds.length || !rootState.user.storage_groups?.length
        ? true
        : rootState.user.storage_groups?.some((group) => groupIds.includes(group.id));
      return rootGetters.hasRole(SIGNERS_AND_OPERATORS) && rootState.user.organization_id === ownerId && hasGroup;
    },

    deletePermission: (state, getters, rootState, rootGetters) => ({groupIds, ownerId}) => {
      if (!Array.isArray(groupIds)) {
        return false;
      }
      const hasGroup = !groupIds.length || !rootState.user.storage_groups?.length
        ? true
        : rootState.user.storage_groups?.some((group) => groupIds.includes(group.id));
      return rootGetters.hasRole(SIGNERS) && rootState.user.organization_id === ownerId && hasGroup;
    },

    signPermission: (state, getters, rootState, rootGetters) => ({groupIds, ownerId}) => {
      if (rootState.user.organization_id !== ownerId || !Array.isArray(groupIds)) {
        return false;
      }
      if (rootGetters.isOwnerAdmin(ownerId)) {
        return true;
      }
      const hasGroup = !groupIds.length || !rootState.user.storage_groups?.length
        ? true
        : rootState.user.storage_groups?.some((group) => groupIds.includes(group.id));
      const hasRole = rootGetters.hasRole([USER_ROLES.CHIEF]) || (rootGetters.hasRole(OPERATORS) && rootGetters
        .hasRole([USER_ROLES.RIGHT_TO_SIGN]));
      return hasRole && hasGroup;
    },

    multipleSignPermission: (state, getters, rootState, rootGetters) => {
      return rootGetters.hasRole(LEAD_ROLES) || (rootGetters.hasRole(OPERATORS) && rootGetters
        .hasRole([USER_ROLES.RIGHT_TO_SIGN]));
    }
  }
};
